exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@charset \"UTF-8\";\r\n\r\n._2fyYD_nHs6Z5NZekdsw2UC {\r\n}\r\n\r\n._3aW6gfoLvxChzY3CXE3god {\r\n}\r\n\r\n._1GNftKBXuzQvEvYabMbuL6 {\r\n  background: #fff6f6 !important;\r\n  color: #9f3a38 !important;\r\n}\r\n\r\n.YV1Vs7cHbHduXTrFixhmh {\r\n  border: 1px solid #9f3a38 !important;\r\n}\r\n\r\n.MmuIBw-RAgX-V6ktnYuK- {\r\n  background: #fffaf3 !important;\r\n  color: #573a08 !important;\r\n}\r\n\r\n._1dx7ak1rVQOLbKFqzM_Sh {\r\n  border: 0 0 0 1px #c9ba9b inset, 0 0 0 0 transparent !important;\r\n}\r\n", ""]);

// exports
exports.locals = {
	"infoDialogColor": "_2fyYD_nHs6Z5NZekdsw2UC",
	"infoDialogContainer": "_3aW6gfoLvxChzY3CXE3god",
	"errorDialogColor": "_1GNftKBXuzQvEvYabMbuL6",
	"errorDialogContainer": "YV1Vs7cHbHduXTrFixhmh",
	"warnDialogColor": "MmuIBw-RAgX-V6ktnYuK-",
	"warnDialogContainer": "_1dx7ak1rVQOLbKFqzM_Sh"
};