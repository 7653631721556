/* eslint no-underscore-dangle: "off", class-methods-use-this: "off", no-console: "off" */

import config from './config.js';
import i18n from 'i18n';

/* Private functions */
function getAccessToken() {
    return app.accessToken;
}


function encodeQueryString(params) {
    // FIXME: If value is [Object], perform JSON.stringify() before encoding
    return Object.keys(params).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`).join('&');
}

/**
 * An api for accessing the server data.
 * It uses ajax calls in order to receive json objects from a php server.
 * */
class Api {
    /**
   * Instantiates a standard api.
   * @param url: the url of the api (server handling the requests).
   * This might be relative to the current domain, or an absolute path.
   * */
    constructor(url) {
        console.log(`Initializing API (${url})`);
        this._url = url;
    }


    /**
   * App Specific Refresh call
   *
   * This is not directed to the API really.
   */
    refresh() {
    // Don't do anything unless we're authenticated (logged in)
        if (!app.store.getState().auth.isSignedIn) {
            return Promise.reject();
        }

        const url = `${app.linkPrefix}refresh`;
        const requestBody = {
            type: 'normal',
            frequency: 60, // FIXME: Should be current interval
            lastResponseTime: null, // FIXME: Should be from last response
            clientVersion: config.clientVersion,
            clientVersionOverride: config.clientVersionOverride || false,
            location: window.location.href, // FIXME: Should be from state
            requests: [],
        };
        const options = {
            method: 'POST',
            body: JSON.stringify(requestBody),
            headers: new Headers({
                'Content-Type': 'application/json; charset=UTF-8',
                'Access-Token': getAccessToken(),
                'X-Chronox-Client': 'ChronoxWeb/2',
            }),
        };
        return new Promise((resolve, reject) => {
            fetch(url, options).then((response) => {
                const data = response.json();
                // If the returned data from the server exists
                if (data) {
                    // Notify the developer and resolve the promise
                    resolve(data);
                    // If the returned data is null or undefined
                } else {
                    // Notify the developer and reject the promise
                    console.warn('Refresh Request failed: no response');
                    reject();
                }
                // If the promise throws any error
            }).catch((error) => {
                // Notify the developer of the error and reject the promise
                console.error('Refresh Request failed');
                console.error(error);
                reject();
            });
        });
    }


    /**
   * Low-level generic call to the Server API (for internal use)
   *
   * API Users should use dedicated methods rather than this.
   *
   * @param {String} method The API method. Eg "auth/login", "users", etc
   * @param {String} type   HTTP Method: "GET", "POST", "DELETE" (not supported yet)
   * @param {Object} params API Method parameters.
   * @param {Bool} signedOutAction Sets whether the call should be allowed
   *                               if the user is signed out.
   *
   * @return {Promise} this promise will either resolve the request with the data from the server
   *                   or reject the request if there was an error.
   */
    call(method, type, params, signedOutAction = false) {
        let url = this._url + method;
        const paramString = encodeQueryString(params);

        let options;
        if (type === 'POST') {
            options = {
                method: 'POST',
                body: JSON.stringify(params),
                headers: new Headers({
                    // 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                    'Content-Type': 'application/json',
                    'Access-Token': getAccessToken(),
                    'X-Chronox-Client': 'ChronoxWeb/2',
                    'Accept-Language': i18n.language,
                }),
            };
        }
        if (type === 'GET') {
            url = `${url}?${paramString}`;
            options = {
                method: 'GET',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Access-Token': getAccessToken(),
                    'X-Chronox-Client': 'ChronoxWeb/2',
                    'Accept-Language': i18n.language,
                }),
            };
        }

        return new Promise((resolve, reject) => {
            fetch(url, options).then((response) => {
                // If the return code is 401, the user has been signed out somehow
                // and needs to log in again.
                if (response.status === 401) {
                    app.ui.hasBeenSignedOut();
                    reject();
                }

                const data = response.json();
                // If the returned data from the server exists
                if (data) {
                    // Notify the developer and resolve the promise
                    console.log(`Ajax request: ${type} ${method}, success`);
                    resolve(data);
                    // If the returned data is null or undefined
                } else {
                    // Notify the developer and reject the promise
                    console.warn(`Ajax request: ${type} ${method}, fail: no response`);
                    reject();
                }
                // If the promise throws any error
            }).catch((error) => {
                // Notify the developer of the error and reject the promise
                console.error(`Ajax request: ${type} ${method}, failed`);
                console.error(error);
                reject();
            });
        });
    }

    /**
   * Perform login and return an accessToken.
   *
   * Response, success (
   *    accessToken: Provided or newly generated token
   *    location: Suggested URL to proceed to
   *    info: Info object (see auth/info). Contains 'company' and 'user'
   * )
   *
   * @param {String} inputUsername username, personnummer, e-mail (not supported yet)
   * @param {String} inputCompany  id, id2 or organisationsnr
   * @param {String} inputPassword plain password / code
   * @param {String} inputMode     Optional hint of intended role, if known.
   *                               Possible values: "employee" or "companyAdmin"
   *
   * @return {Promise}
   */
    login(inputUsername, inputCompany, inputPassword, inputMode = null) {
        return new Promise((resolve, reject) => {
            const body = {
                username: inputUsername,
                password: inputPassword,
                mode: inputMode,
            };

            if (inputCompany) {
                body.company = inputCompany;
            }

            this.call('auth/login', 'POST', body, true).then((response) => {
                // The cookie and response.location manipulation has moved to UIHandler::login()
                resolve(response);
            }).catch(() => {
                reject();
            });
        });
    }

    /**
   * Perform logout
   *
   * @todo should reset accessToken and/or user stuff [hg]
   *
   * @return {Promise}
   * */
    logout() {
        return this.call('auth/logout', 'POST', {});
    }

    /**
    * Get authentication status and info
    *
    * Checks the status for the current accessToken
    *
    * Response, success [InfoObject] (
    *   company: [CompanyBrief]
    *   user: [UserBrief]
    *   menu: List of available menu items (for web client)
    * )
    *
    * @return {Promise}
    * */
    getInfo() {
        return this.call('auth/info', 'GET', {}, true);
    }

    getUserCompanies(username = null, company = null) {
        // As long as both username and company is NOT null,
        // call the api with username, to retrieve a list of associated companies, or
        // call the api with company (id2 or name) to get the full information about that company.
        // If both username and company is entered, username is used.
        if (username !== company) {
            if (username) {
                return this.call('auth/companies', 'GET', { username }, true);
            } else if (company) {
                return this.call('auth/companies', 'GET', { company }, true);
            }
        }

        // If all else fails, reject the request
        return new Promise((resolve, reject) => reject());
    }


    getReportsTimesheetsDays(filter, select) {
        return this.call('reports/timesheets/days', 'GET', {
            filter: 'not-completed',
            select: JSON.stringify({ rows: { config: true, items: true } })
        });
    }


    addReportsTimesheetsItems(item, select) {
        const timesheetItem = {
            date: '2019-01-08',
            status: 'A',
            project: 60,
            hours: 5.25,
            timeStart: '12:05',
            timeEnd: '19:06',
            self: true,
            travel: 123,
            car: false,
            attachment: {
                1: {
                    status: 'new',
                    name: 'logo-web@x2.png',
                    tempId: 'phpfG3Chi',
                    size: 14420,
                    type: 'image/png',
                },
            },
            comment: 'some notes here',
        };
        return this.call('reports/timesheets/items', 'POST', {
            timesheetItem,
            select: { timesheetItem: true, timesheetDay: { config: true, items: true } }
        });
    }


    /**
     * Get an old component (full html).
     * This method is mainly used by the classes HTMLDocumentComponent and OldComponent.
     * If an old component is to be loaded,
     * extend these classes (see ReportsToApproveTable.jsx for an example).
     *
     * @param name: the name of the old component.
     * @param params:
     * */
    getOldComponent(name, params) {
        const state = app.store.getState();
        let url = state.contentComponentApiLocation.replace(':company', state.company.id2) + name;
        const paramString = encodeQueryString(params);
        url += (paramString ? (`?${paramString}`) : '');

        const options = {
            method: 'GET',
            headers: new Headers({
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Token': getAccessToken(),
                'Accept-Language': i18n.language,
            }),
        };

        return new Promise((resolve, reject) => {
            fetch(url, options).then((response) => {
                // If the return code is 401, the user has been signed out somehow
                // and needs to log in again.
                if (response.status === 401) {
                    app.ui.hasBeenSignedOut();
                }

                const data = response.json();
                if (data) {
                    console.log(`getOldComponent: ${name}, success`);
                    resolve(data);
                } else {
                    console.warn(`getOldComponent: ${name}, fail: no response`);
                    reject();
                }
            }).catch((error) => {
                console.error(`getOldComponent: ${name}, failed`);
                console.error(error);
                reject();
            });
        });
    }

    getOldView(name, params) {
        const state = app.store.getState();
        let url = state.contentViewApiLocation.replace(':company', state.company.id2) + name;
        const paramString = encodeQueryString(params);
        url += (paramString ? (`?${paramString}`) : '');

        const options = {
            method: 'GET',
            headers: new Headers({
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Token': getAccessToken(),
                'Accept-Language': i18n.language,
            }),
        };

        return new Promise((resolve, reject) => {
            fetch(url, options).then((response) => {
                // If the return code is 401, the user has been signed out somehow
                // and needs to log in again.
                if (response.status === 401) {
                    app.ui.hasBeenSignedOut();
                }

                const data = response.json();
                if (data) {
                    console.log(`getOldView: ${url}, success`);
                    resolve(data);
                } else {
                    console.warn(`getOldView: ${url}, fail: no response`);
                    reject();
                }
            }).catch((error) => {
                console.error(`getOldView: ${url}, failed`);
                console.error(error);
                reject();
            });
        });
    }

    /**
     * Returns the days that the currently signed in user
     * has yet to report for.
     * This method returns a promise.
     */
    getDaysToReportFor() {
        return new Promise((resolve, reject) => {
            this.call('reports/timesheets/days', 'GET', {
                filter: 'not-completed',
            })
                // On success, resolve the returned promise.
                // with the fetched data.
                .then(response => resolve(response))
                // On error, reject the promise.
                .catch(error => reject(error));
        });
    }

    /**
     * Saves the values of the specified date. i.e. saves a day report item.
     *
     * @param {Date} date the date of the day, which acts as a key.
     * @param {Object} formValues the key-value pair style object
     *   containing the values of the day to submit.
     */
    saveTimeSheetDay(date, formValues) {
        // The request object to use with the request.
        const request = {
            // The item to save.
            timesheetItem: formValues,
            // The updated values to retrieve from the server
            // after the request has been handled.
            select: {
                timesheetItem: true,
                timesheetDay: {
                    config: true,
                    items: true,
                },
            },
        };

        return new Promise((resolve, reject) => {
            this.call('reports/timesheets/items', 'POST', request)
                // On success, resolve the returned promise.
                // with the fetched data.
                .then(response => resolve(response))
                // On error, reject the promise.
                .catch(error => reject(error));
        });
    }
}

export default Api;
