import React from 'react';
import PropTypes from 'prop-types';
import { Form, TextArea } from 'semantic-ui-react';

class TextField extends React.Component {
    render() {
        const {
            id,
            label,
            placeholder,
            onChange,
        } = this.props;

        // Function that handles the event of a value change of
        // the text field. It updates the local state and ItemEditForm
        // with the updated value of the field.
        const handleInputChange = (event, object) => {
            if (object.value.length <= 80) {
                onChange(id, object.value);
            }
        };

        const element = (
            <Form.Field
                control={TextArea}
                label={label}
                placeholder={placeholder}
                onChange={handleInputChange}
                autoHeight
                style={{ minHeight: 100 }}
            />
        );
        return element;
    }
}

TextField.defaultProps = {
    onChange: () => console.warn('No onChange function passed down to TextField'),
};

TextField.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    onChange: PropTypes.func,
};

export default TextField;
