import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import { setLedger, setGrouped } from 'Actions';
import LedgerPageEmployee from './LedgerPageEmployee.jsx';

class LedgerPageFactory extends React.Component {
    render() {
        const {
            currentUserRole,
            params,
            setCurrentLedger,
            setGroupedStatus,
            query,
            t,
        } = this.props;
        const ledger = params.ledger || 1;
        const grouped = query.grouped === '1';

        setCurrentLedger(ledger);
        setGroupedStatus(grouped);

        let element = (
            <div>
                LedgerPage
            </div>
        );

        switch (currentUserRole) {
            case 'companyAdmin':
                element = <div>Admin Ledger Page</div>;
                break;
            case 'employee':
                element = <LedgerPageEmployee />;
                break;
            default:
                element = <div>{t('error.loadingError')}</div>;
                break;
        }

        return element;
    }
}

LedgerPageFactory.propTypes = {
    currentUserRole: PropTypes.string.isRequired,
    params: PropTypes.shape({
        ledger: PropTypes.string.isRequired,
    }).isRequired,
    query: PropTypes.shape({
        grouped: PropTypes.string.isRequired,
    }).isRequired,
    setCurrentLedger: PropTypes.func.isRequired,
    setGroupedStatus: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

const mapStateToProps = state => Object.assign({}, {
    currentUserRole: state.auth.currentRole,
});

const mapDispatchToProps = dispatch => Object.assign({}, {
    setCurrentLedger: ledgerId => dispatch(setLedger(ledgerId)),
    setGroupedStatus: isGrouped => dispatch(setGrouped(isGrouped)),
});

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(LedgerPageFactory);
const finalComponent = translate('Ledger')(connectedComponent);

export default finalComponent;
