import React from 'react';
import PropTypes from 'prop-types';
import { Form, Select } from 'semantic-ui-react';

class ProjectField extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hasSentNull: false,
            value: null,
        };

        this.handleSelectChange = this.handleSelectChange.bind(this);
    }

    componentDidUpdate() {
        // If the options field gets disabled
        // the value of the parent needs to be set to null,
        // in order to prevent an invalid value
        // To be used. We also need to make sure to
        // not set it to null if we already have.
        if (this.props.disabled && !this.state.hasSentNull) {
            this.props.onChange(this.props.id, null);
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ hasSentNull: true });
        }

        // Likewise, if the options field is not disabled
        // anylonger and the value was set to null
        // the parents value needs to be set back to the original value.
        if (!this.props.disabled && this.state.hasSentNull) {
            this.props.onChange(this.props.id, this.state.value);
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ hasSentNull: false });
        }
    }

    handleSelectChange(event, object) {
        // Update the local state and
        // ItemEditForm with the updated value
        // of the field.
        this.props.onChange(this.props.id, object.value);
        this.setState({ value: object.value });
    }

    render() {
        const {
            projects,
            label,
            placeholder,
            defaultOption,
            disabled,
        } = this.props;

        // Build the options to use when rendering.
        const options = projects.map(project => ({
            key: project.code,
            text: project.name,
            value: project.code,
        }));

        const element = (
            <Form.Field
                control={Select}
                label={label}
                options={options}
                placeholder={placeholder}
                onChange={this.handleSelectChange}
                defaultValue={defaultOption}
                disabled={disabled}
            />
        );
        return element;
    }
}

ProjectField.defaultProps = {
    defaultOption: undefined,
    disabled: false,
    onChange: () => console.warn('No onChange function passed down to ProjectField'),
};

ProjectField.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    projects: PropTypes.arrayOf(PropTypes.shape({
        code: PropTypes.any.isRequired,
        name: PropTypes.string.isRequired,
    })).isRequired,
    defaultOption: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
};

export default ProjectField;
