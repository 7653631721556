import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Input, Button } from 'semantic-ui-react';

import css from 'css/LoginForm.css';
import ChronoxDropdown from './Dropdown/ChronoxDropdown';
import LockableArea from './Wrappers/LockableArea';
import { LOGIN_REQUEST, LOGIN_FAILURE } from '../Actions/constants';

function handleFocus() {
    app.ui.loginFocus();
}

/**
 * The LoginForm handles login. UI wise, it contains only two input fields and a button.
 * An application component should be created,
 * in order to wrap the form and make some specific styling.
 */
class LoginForm extends React.Component {
    constructor(props) {
        super(props);

        /* The initial state of the component */
        this.state = {
            // Initialize the username as blank
            username: '',
            // Initialize the password as blank
            password: '',
            // The companies of the entered user.
            // This is updated when a complete and valid personal number is entered.
            companies: [],
            companySelectOptions: [],
            // The "god-given" company
            company: props.company,
            // Set to not show the loader when the user has done history.back (Web only)
            isLoadingOverride: false,
        };

        // Bind the methods,
        // in order for the login method to be accessible within the JSX code (render method)
        this.login = this.login.bind(this);
        this.usernameChange = this.usernameChange.bind(this);
        this.passwordChange = this.passwordChange.bind(this);
        this.companyChange = this.companyChange.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.focusField = this.focusField.bind(this);

        // Lookup with Refs to our input fields
        this.fields = {
            username: null,
            company: null,
            passowrd: null,
        };

        this.requests = [];

        // This is fixes the problem of the spinner being stuck if a user
        // uses the browser history back function, to go back to the login.
        // The isLoadingOverride flag in the state acts as an override.
        // If set to true, it overrides the isLoading prop.
        // In most browsers, the unload event is where the page cache occurs.
        // This means that this fix overrides the caching of the page,
        // which ultimately solves the issue. We can still keep,
        // the isLoadingOverride, to be safe.
        this.unloadListener = (event) => {
            event.preventDefault();
            this.setState({ isLoadingOverride: true });
        };
        window.addEventListener('unload', this.unloadListener);
    }

    /* FIXME: update the ref handling to use React.createRef(),
       which was introduced in react 16.3 */
    componentDidMount() {
        this.fields.username.focus();
    }


    componentWillReceiveProps(nextProps) {
    // Only react if we have gone from a company to another, or one to none
        if (this.state.company && nextProps.company) {
            this.state.company = nextProps.company;
        }
        // XXX: not sure if this is right
        if (this.props.company && nextProps.company == null) {
            this.state.companies = [];
            this.state.companySelectOptions = [];
            this.state.company = null;
            this.state.username = '';
            this.state.password = '';
        }
    }

    componentWillUnmount() {
        // We need to reject (cancel) all requests if the component is unmounted.
        // Otherwise the state may get updated and an error is logged.
        this.requests.forEach(request => request.reject('Component was unmounted.'));
        // For the same reason, the unload listener needs to be removed.
        window.removeEventListener('unload', this.unloadListener);
    }


    login() {
    // Reset the unload override
        this.setState({ isLoadingOverride: false });

        // Only use the selected company if there are more than one or if we are locked to a company
        let companyId = null;
        if (this.props.company) {
            companyId = this.props.company.id2;
        } else if (this.state.company && this.state.companies.length > 1) {
            companyId = this.state.company.id2;
        }
        app.ui.login(this.state.username, companyId, this.state.password);
    }

    /**
    * This method should be called whenever the username field is change
    * (i.e. onChange={usernameChange}).
    *
    * @param event the event that triggered the call.
    * This contains (for instance) information about what new value the username field obtained.
    */
    usernameChange(event) {
        const newValue = event.target.value;

        this.setState({ username: newValue });

        // If the username has been cleared, clear the password as well
        if (newValue.length <= 0) {
            this.setState({ password: '' });
            return;
        }

        // Only if we don't have a given company and the companies have not already been fetched
        // XXX: Problems are that this is done all the time,
        // and also that it isn't reload if the user changes
        if (this.props.company == null) {
            this.updateCompanies(newValue);
        }
    }


    /**
   * Update the internal list of companies for the username
   *
   * @param {String}
   */
    updateCompanies(newValue) {
        // newValue = this.state.username;
        const request = app.api.getUserCompanies(newValue);
        this.requests.push(request);

        request.then((response) => {
            this.setState({
                companies: response.companies,
                companySelectOptions: response.companies.map(company => Object.assign({}, {
                    text: company.name,
                    value: company.id2,
                    image: {
                        rounded: true,
                        src: company.logoUrl,
                        floated: 'right',
                    },
                })),
            });

            // If only a single company we select it for the user
            if (response.companies.length === 1) {
                this.setState({
                    company: response.companies[0],
                });
                this.props.onCompanyChange(response.companies[0]);
            }

        // The catch is needed, even if no handler exists
        }).catch(() => {});
    }


    /**
   * This method should be called whenever the
   * password field is change (i.e. onChange={passwordChange}).
   *
   * @param event the event that triggered the call.
   * This contains (for instance) information about what new value the password field obtained.
   */
    passwordChange(event) {
    // Update the password variable, with the change
        this.setState({ password: event.target.value });
    }

    companyChange(event, { value }) {
    // Find the company in our list
        const selectedCompanyId2 = value;
        const selectedCompany = this.state.companies.find(company =>
            company.id2 === selectedCompanyId2);

        // Simplified?
        if (selectedCompany !== undefined) {
            this.setState({ company: selectedCompany });
            this.props.onCompanyChange(selectedCompany);
        } else {
            this.setState({ company: null });
            this.props.onCompanyChange(null);
        }
    }

    handleFormSubmit(event) {
        event.preventDefault();

        if (this.state.username.length > 0 && this.state.password.length > 0) {
            this.login();
        }
    }

    focusField(key) {
        this.fields[key].focus();
    }

    render() {
        const { companySelectOptions, isLoadingOverride } = this.state;
        const {
            t,
            loginError,
            isLoading,
        } = this.props;

        // Logic
        const loginDisabled =
          this.state.username.length < 1 ||
          this.state.password.length < 1 ||
          (!this.props.company && companySelectOptions.length > 1 && !this.state.company);
        const dropdownHidden = this.props.company !== null || companySelectOptions.length < 2;

        const element = (
            <LockableArea active={!isLoadingOverride && isLoading}>
                <form onSubmit={this.handleFormSubmit}>
                    <Input name="ReporterName" className={css.chronoxInput} error={loginError} icon="user" iconPosition="left" placeholder={t('Common:xxx.usernameReporter')} fluid onChange={this.usernameChange} onFocus={handleFocus} ref={(input) => { this.fields.username = input; }} />
                    <ChronoxDropdown placeholder={(this.state.company ? this.state.company.name : t('Toolkit:LoginForm.chooseCompany'))} icon="industry" options={companySelectOptions} hidden={dropdownHidden} onChange={this.companyChange} ref={(input) => { this.fields.company = input; }} />
                    <Input name="ReporterPassword" className={css.chronoxInput} error={loginError} icon="lock" iconPosition="left" placeholder={t('Toolkit:LoginForm.password')} fluid type="password" onChange={this.passwordChange} onFocus={handleFocus} value={this.state.password} ref={(input) => { this.fields.password = input; }} />
                    <Button type="submit" color="blue" fluid disabled={loginDisabled}>{t('Toolkit:common.button.login')}</Button>
                </form>
            </LockableArea>
        );

        return element;
    }
}

LoginForm.propTypes = {
    company: PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.string,
        id2: PropTypes.string,
    }),
    onCompanyChange: PropTypes.func,
    isLoading: PropTypes.bool,
    t: PropTypes.func.isRequired,
    loginError: PropTypes.bool.isRequired,
};

LoginForm.defaultProps = {
    onCompanyChange: () => {},
    isLoading: false,
    company: null,
};

const mapStateToProps = state => Object.assign({}, {
    company: state.company,
    // If there is a login error, set the loginError prop to true
    loginError: state.currentErrors.filter(error => error.type === LOGIN_FAILURE).length > 0,
    // If there is a login request (or success), set the isLoading prop to true
    isLoading: state.currentRequests.filter(request =>
        request.type === LOGIN_REQUEST).length > 0 || state.auth.isSignedIn,
});

const LoginFormContainer = connect(mapStateToProps)(LoginForm);

export default translate(['Common', 'Toolkit'])(LoginFormContainer);
