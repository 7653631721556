import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'semantic-ui-react';

class InputField extends React.Component {
    render() {
        const {
            id,
            label,
            placeholder,
            onChange,
        } = this.props;

        // Function that handles the event of a value change of
        // the input. It updates the local state and ItemEditForm
        // with the updated value of the field.
        const handleInputChange = (event, object) => onChange(id, object.value);

        const element = (
            <Form.Field
                control={Input}
                label={label}
                placeholder={placeholder}
                onChange={handleInputChange}
            />
        );
        return element;
    }
}

InputField.defaultProps = {
    onChange: () => console.warn('No onChange function passed down to InputField'),
};

InputField.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    onChange: PropTypes.func,
};

export default InputField;
