/* eslint import/no-unresolved: off, import/extensions: off */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TransitionablePortal, Message } from 'semantic-ui-react';
import { translate } from 'react-i18next';

import LoginWindow from 'Container/LoginWindow';
import { Chronox } from 'Toolkit/Logo';
import { LanguageChooser } from 'Toolkit/Utils/LanguageChooser';
import { ChronoxBox, View } from 'Toolkit';
import i18n from 'i18n';
import styles from 'css/login.css';
import { LOGIN_FAILURE } from '../Actions/constants';

function handleLanguageChange(language) {
    i18n.changeLanguage(language);
}

/**
 * This component is dependent on the i18n instance,
 * and needs webpack (or similar build environment) to create an alias for i18n, since
 * it imports the instance like the following -> import i18n from 'i18n'.
 * */
class ScreenSignIn extends React.Component {
    render() {
        const { t, errorMessageOpen } = this.props;
        const currentLanguage = i18n.language;

        const languageChooserElement = (
            <LanguageChooser options={[{ countryCode: 'se', languageCode: 'sv' }, { countryCode: 'gb', languageCode: 'en' }]} value={currentLanguage} onChange={handleLanguageChange} />
        );

        const element = (
            <View id="container" style={styles.loginContainer}>
                <TransitionablePortal open={errorMessageOpen} transition={{ animation: 'fade down', duration: 500 }}>
                    <Message
                        style={{
                            position: 'fixed',
                            top: '10px',
                            left: 0,
                            right: 0,
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            zIndex: 1000,
                            width: '30%',
                            minWidth: '300px',
                        }}
                        negative
                    >
                        <p>{t('wrongAuthenticationDetails')}</p>
                    </Message>
                </TransitionablePortal>
                <ChronoxBox full>
                    <ChronoxBox.Row>
                        <Chronox style={{ bottom: 0 }} size="medium" />
                    </ChronoxBox.Row>
                    <ChronoxBox.Row>
                        <LoginWindow />
                    <ChronoxBox.Row>
                        <div className="SocialMediaBlock_UI">
                            {/* Writes out "V�nligen f�lj oss p� sociala medier:" via ascii as a workaround */}
                        <p>{String.fromCharCode(86, 228, 110, 108, 105, 103, 101, 110, 32, 102, 246, 108, 106, 32, 111, 115, 115, 32, 112, 229, 32, 115, 111, 99, 105, 97, 108, 97, 32, 109, 101, 100, 105, 101, 114, 58)}</p>
                        <ul>
                            <li className="facebook socialIcons">
                                <a href="https://www.facebook.com/chronox.tidrapportering"><i class="fa fa-facebook-square fa-2x"></i></a>
                            </li>
                            <li className="instagram socialIcons">
                                <a href="https://www.instagram.com/chronox.se/"><i class="fa fa-instagram fa-2x"></i></a>
                            </li>
                            <li className="linkedin socialIcons">
                                <a href="https://www.linkedin.com/company/chronox-tidrapportering/"><i class="fa fa-linkedin fa-2x"></i></a>
                            </li>
                        </ul> 
                        </div>
                    </ChronoxBox.Row>
                    </ChronoxBox.Row>
                    <ChronoxBox.Row>
                        {/* Only show the language chooser element in development mode */}
                        {i18n.shouldChooseLanguage() ? languageChooserElement : null}
                        <p>{t('webbased')}, <a href="//www.chronox.se/">{t('readmore')}.</a></p>
                    </ChronoxBox.Row>
                </ChronoxBox>
            </View>
        );

        return element;
    }
}

ScreenSignIn.defaultProps = {
    errorMessageOpen: false,
};

ScreenSignIn.propTypes = {
    t: PropTypes.func.isRequired,
    errorMessageOpen: PropTypes.bool,
};

// Maps the state of redux to the props of the component
const mapStateToProps = state => Object.assign({}, {
    errorMessageOpen: state.currentErrors.filter(error => error.type === LOGIN_FAILURE).length > 0,
});

// Connect component to redux
const ScreenSignInConnected = connect(mapStateToProps)(ScreenSignIn);

export default translate()(ScreenSignInConnected);
