import React from 'react';
import { Grid } from 'semantic-ui-react';

import { View } from 'Toolkit';
import OldView from 'Toolkit/OldView.jsx';

import OverviewMessagesBlock from 'Container/OverviewMessagesBlock.jsx';
import LedgerOverviewBlock from 'Container/LedgerOverviewBlock.jsx';
import ReportsToApproveTable from 'Container/ReportsToApproveTable.jsx';
import OldComponent from 'Toolkit/OldComponent.jsx';

class PageOverview extends React.Component {
  render() {
	  // This element will be used (for admins) when the application is rewritten in react
	  const reactElement = (
		  <View>
              <Grid stackable columns={2}>
				  <Grid.Column width={8}>
					  <LedgerOverviewBlock />
					  <h2 className="forBox">Inkomna rapporter att attestera</h2>
					  <ReportsToApproveTable />
				  </Grid.Column>
				  <Grid.Column width={8}>
					  <h2 className="forBox">Ej inkomna rapporter</h2>
					  <OldComponent name="ReportsToSubmitTable" />
				  </Grid.Column>
              </Grid>
			  <OverviewMessagesBlock />
		  </View>
      );

	  const oldView = <OldView refresh={false} mode="index" />

	  return oldView;
  }
}

export default PageOverview;