exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@charset \"UTF-8\";\r\n\r\n/*---------------DEFAULT (DESKTOP)---------------*/\r\n._3m8SkTIV5a_IfdM369NPDB {\r\n    position: relative; /* In order for this to work with webkit */\r\n    display: none;\r\n    z-index: 1; /* 1 means that the device is of desktop size */\r\n}\r\n\r\n/*---------------SMALL DESKTOP---------------*/\r\n@media only screen and (max-width: 1200px) {\r\n    ._3m8SkTIV5a_IfdM369NPDB {\r\n        display: none;\r\n        z-index: 2; /* 2 means that the device is of small desktop size */\r\n    }\r\n}\r\n\r\n/*---------------TABLET---------------*/\r\n@media only screen and (max-width: 1024px) {\r\n    ._3m8SkTIV5a_IfdM369NPDB {\r\n        display: none;\r\n        z-index: 3; /* 3 means that the device is of tablet size */\r\n    }\r\n}\r\n\r\n/*---------------MOBILE---------------*/\r\n@media only screen and (max-width: 768px) {\r\n    ._3m8SkTIV5a_IfdM369NPDB {\r\n        display: none;\r\n        z-index: 4; /* 4 means that the device is of mobile size */\r\n    }\r\n}", ""]);

// exports
exports.locals = {
	"deviceTest": "_3m8SkTIV5a_IfdM369NPDB"
};