import React from 'react';
import PropTypes from 'prop-types';

import { translate } from 'react-i18next';

import { TabbedLayout } from 'Toolkit';
import SnapshotTab from './Tabs/SnapshotTab.jsx';
import CheckInOutTab from './Tabs/CheckInOutTab.jsx';
import LogTab from './Tabs/LogTab.jsx';
import WorkShiftsTab from './Tabs/WorkShiftsTab.jsx';
import HeaderSection from './HeaderSection.jsx';

class LedgerPageEmployee extends React.Component {
    componentDidMount() {
        // WARNING: Global dependencies
        const AppDocument = app ? app.document : null;
        const cssLocation = `${app.store.getState().urlPrefix}dist/_css/`;
        const ledgerStyleSheets = [
            { href: `${cssLocation}font-awesome.min.css`, media: 'all', title: null },
            { href: `${cssLocation}semantic.css`, media: 'all', title: null },
            { href: `${cssLocation}common.css`, media: 'all', title: null },
            { href: `${cssLocation}NewForm.css`, media: 'all', title: null },
            { href: `${cssLocation}screen.css`, media: 'screen,handheld', title: null },
            { href: `${cssLocation}print.css`, media: 'print', title: null },
            { href: `${cssLocation}admin-components.css`, media: 'all', title: null },
            { href: `${cssLocation}ledger.css`, media: 'all', title: null },
        ];
        const ledgerStyleRules = [
            '.editActivity .fieldcontain input[disabled] { background-color: #eee; color: #999; font-weight: normal; } .editActivity .fieldcontain legend {	width: 50%; display: block;	float: left; }.LedgerWorkShiftsBlock .filter h2 { margin-top: 0; } .LedgerWorkShiftsBlock .filter .DateRange input { width: 100px; } .LedgerWorkShiftsBlock .dataTable td .actions { min-width: 3em; } .LedgerWorkShiftsBlock .dataTable td .identification { font-size: smaller; color: #666; }',
        ];

        //ledgerStyleSheets.forEach(styleSheet => AppDocument.addStylesheet(styleSheet));
        //ledgerStyleRules.forEach(styleRules => AppDocument.addStyleRules(styleRules));
    }

    render() {
        const { t } = this.props;

        const element = (
            <TabbedLayout>
                <TabbedLayout.Header>
                    <HeaderSection />
                </TabbedLayout.Header>
                <TabbedLayout.Tab title={t('tabs.snapshotTabTitle')}>
                    <SnapshotTab />
                </TabbedLayout.Tab>
                <TabbedLayout.Tab title={t('tabs.checkInOutTabTitle')}>
                    <CheckInOutTab />
                </TabbedLayout.Tab>
                <TabbedLayout.Tab title={t('tabs.logTabTitle')}>
                    <LogTab />
                </TabbedLayout.Tab>
                <TabbedLayout.Tab title={t('tabs.workShiftsTabTitle')}>
                    <WorkShiftsTab />
                </TabbedLayout.Tab>
            </TabbedLayout>
        );

        return element;
    }
}

LedgerPageEmployee.propTypes = {
    t: PropTypes.func.isRequired,
};

const finalComponent = translate('Ledger')(LedgerPageEmployee);

export default finalComponent;
