import {
  PAGE_LOAD,
  LOGIN_FOCUS,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  SET_LOGIN_BOX_TAB,
  SET_COMPANY,
  SET_MENU,
  SET_BODY_CLASSES,
  SET_LANGUAGE_DROPDOWN_STATUS,
  SIDEBAR_CLICK,
  PAGE_CHANGE,
  CLICK_ON_HEADER,
  CLICK_ON_CONTENT,
  NEW_VERSION_ALERT,
  CENTRAL_DIALOG_OPEN,
  CENTRAL_DIALOG_CLOSE,
  LANGUAGE_CHANGE,
  QUICK_REPORT_DAY_CHANGE,
  SET_QUICK_REPORT_PROJECTS,
  SET_LEDGER,
  SET_GROUPED,
} from './constants';

export function pageLoad(info) {
    return { type: PAGE_LOAD, info };
}

export function loginFocus() {
    return { type: LOGIN_FOCUS };
}

export function loginRequest() {
    return { type: LOGIN_REQUEST };
}

export function loginSuccess(initialData) {
    return { type: LOGIN_SUCCESS, initialData };
}

export function loginFailure(errorObject) {
    return { type: LOGIN_FAILURE, errorObject };
}

export function logout() {
    return { type: LOGOUT };
}

export function setLoginBoxTab(tabIndex) {
    return { type: SET_LOGIN_BOX_TAB, tabIndex };
}

export function setCompany(company) {
    return { type: SET_COMPANY, company };
}

export function setMenu(menu) {
    return { type: SET_MENU, menu };
}

export function setBodyClasses(classes) {
    return { type: SET_BODY_CLASSES, classes };
}

export function setLanguageDropdownStatus(status) {
    return { type: SET_LANGUAGE_DROPDOWN_STATUS, status };
}

export function sidebarClick() {
    return { type: SIDEBAR_CLICK };
}

export function pageChange() {
    return { type: PAGE_CHANGE };
}

export function clickOnHeader() {
    return { type: CLICK_ON_HEADER };
}

export function clickOnContent() {
    return { type: CLICK_ON_CONTENT };
}

export function newVersionAlert(forceRefresh) {
    return { type: NEW_VERSION_ALERT, forceRefresh };
}

export function centralDialogOpen(type, message, header = null, callback = null) {
    return {
        type: CENTRAL_DIALOG_OPEN,
        dialog: {
            type,
            message,
            header,
            callback,
        },
    };
}

export function centralDialogCloseAction() {
    return { type: CENTRAL_DIALOG_CLOSE };
}

export function languageChange(language) {
    return { type: LANGUAGE_CHANGE, language };
}

export function setLedger(ledgerId) {
    return { type: SET_LEDGER, ledgerId };
}

export function setGrouped(isGrouped) {
    return { type: SET_GROUPED, isGrouped };
}

export function quickReportDayChange(date, formValues) {
    return { type: QUICK_REPORT_DAY_CHANGE, date, formValues };
}

export function setQuickReportProjects(projects) {
    return { type: SET_QUICK_REPORT_PROJECTS, projects };
}
