import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import WorkShiftsBlock from '../../Ledger/WorkShiftsBlock.jsx';

/*
 * This is a container component for the
 * App component App/Ledger/WorkShiftsBlock.
 * DO NOT MIX THEM UP!
 */
function WorkShiftsBlockContainer(props) {
    const { ledgerId, isGrouped } = props;
    return <WorkShiftsBlock ledgerId={ledgerId} isGrouped={isGrouped} />;
}

WorkShiftsBlockContainer.propTypes = {
    ledgerId: PropTypes.string.isRequired,
    isGrouped: PropTypes.bool.isRequired,
};

const mapStateToProps = state => Object.assign({}, {
    ledgerId: state.ledger.current,
    isGrouped: state.ledger.isGrouped,
});

const connectedComponent = connect(mapStateToProps, null)(WorkShiftsBlockContainer);
const finalComponent = connectedComponent;

export default finalComponent;
