import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { Input, Button } from 'semantic-ui-react';

import css from 'css/LoginForm.css';
import LockableArea from './Wrappers/LockableArea';
import { LOGIN_REQUEST, LOGIN_FAILURE } from '../Actions/constants';

// Called when any (input) field receives focus
function handleFocus() {
    app.ui.loginFocus();
}

/**
 * The LoginForm handles login. UI wise, it contains only two input fields and a button.
 * An application component should be created,
 * in order to wrap the form and make some specific styling.
 *
 * PROPS:
 * - company (mapped)       The permanent company, only set if we are locked to this company
 * - isLoading (mapped)
 * - loginError (mapped)
 * - onCompanyChange        f()
 */
class LoginForm extends React.Component {
    constructor(props) {
        super(props);

        /* The initial state of the component */
        this.state = {
            // Initialize the username as blank
            username: '',
            // Initialize the password as blank
            password: '',
            // The current selected company, initialize with the "god-given" company (if any)
            company: props.company,
            // Set to not show the loader when the user has done history.back (Web only)
            isLoadingOverride: false,
        };

        // Bind the methods,
        // in order for the login method to be accessible within the JSX code (render method)
        this.login = this.login.bind(this);
        this.usernameChange = this.usernameChange.bind(this);
        this.passwordChange = this.passwordChange.bind(this);
        this.organisationNumberChange = this.organisationNumberChange.bind(this);
        this.focusField = this.focusField.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);

        // Lookup with Refs to our input fields
        this.fields = {
            company: null,
            username: null,
            passowrd: null,
        };
    }


    componentDidMount() {
        const userPasswordDisable = this.props.company === null;

        if (!userPasswordDisable) {
            this.fields.username.focus();
        }
    }


    login() {
        if (this.state.company) {
            app.ui.login(this.state.username, this.state.company.id2, this.state.password, 'companyAdmin');
        }
    }


    /**
   * This method should be called whenever
   * the username field is changed (i.e. onChange={usernameChange}).
   *
   * @param event the event that triggered the call.
   *              This contains (for instance)
   *              information about what new value the username field obtained.
   */
    usernameChange(event) {
        this.setState({ username: event.target.value }); // Update the username variable
    }


    /**
   * This method should be called whenever the password field is changed
   * (i.e. onChange={passwordChange}).
   *
   * @param event the event that triggered the call. This contains (for instance)
   * information about what new value the password field obtained.
   */
    passwordChange(event) {
    // Update the password variable, with the change
        this.setState({ password: event.target.value });
    }


    /**
   * This method should be called whenever the organisation number field is changed
   * (i.e. onChange={organisationNumberChange}).
   *
   * Improvements
   * - Delay request for about 1-2 seconds
   * - If a request is ongoing, cancel it, and queue a new one (with delay)
   * - If a request is queued (not yet executed), update and delay it.
   *
   * x = new DelayedUpdater();
   * x.delay = 2000;
   * x.update(function() or Promise)
   * x.cancel()
   *
   * @param event the event that triggered the call. This contains (for instance)
   * information about what new value the organisationNumber field obtained.
   */
    organisationNumberChange(event) {
        const input = event.target.value;

        // Once the whole organisation number is entered
        if (this.props.company === null && input) {
            // Get the company
            app.api.getUserCompanies(null, input).then((response) => {
                let company = null;

                // @todo: Prevent us overwriting if the value has changed since we did our request
                // If a valid company was returned
                if (response.companies.length === 1) {
                    [company] = response.companies;

                    // Enable the username and login fields. Update the company state variable.
                    this.setState({ company });
                    this.focusField('username');
                } else {
                    this.setState({ company: null });
                }

                // If the is a callback
                if (this.props.onCompanyChange) {
                    // Notify with the fetched company
                    this.props.onCompanyChange(company);
                }
            });
        } else if (this.props.onCompanyChange) {
            this.props.onCompanyChange(null);
        }
    }

    handleFormSubmit(event) {
        event.preventDefault();

        if (
            this.state.username.length > 0 &&
            this.state.password.length > 0 &&
            this.state.company
        ) {
            this.login();
        }
    }

    focusField(key) {
        this.fields[key].focus();
    }

    render() {
        const { isLoadingOverride } = this.state;
        const loginDisabled =
            this.state.company === null ||
            this.state.username.length < 1 ||
            this.state.password.length < 1;
        const userPasswordDisable = this.state.company === null;
        const { t, loginError, isLoading } = this.props;

        return (
            <LockableArea active={!isLoadingOverride && isLoading}>
                <form name="AdminLoginForm" onSubmit={this.handleFormSubmit}>
                    {this.props.company === null &&
                        <Input name="AdminOrganization" className={css.chronoxInput} error={loginError} autoComplete="organization" icon="industry" iconPosition="left" placeholder={t('Common:term.companyIdentityNumber')} fluid onChange={this.organisationNumberChange} onFocus={handleFocus} defaultValue={this.props.company ? this.props.company.name : ''} />
                    }
                    <Input name="AdminUsername" className={css.chronoxInput} disabled={userPasswordDisable} error={loginError} icon="user" iconPosition="left" placeholder={t('Toolkit:LoginForm.username')} fluid onChange={this.usernameChange} onFocus={handleFocus} ref={(input) => { this.fields.username = input; }} />
                    <Input name="AdminPassword" className={css.chronoxInput} disabled={userPasswordDisable} error={loginError} icon="lock" iconPosition="left" placeholder={t('Toolkit:LoginForm.password')} fluid type="password" onChange={this.passwordChange} onFocus={handleFocus} />
                    <Button type="submit" color="blue" fluid disabled={loginDisabled}>{t('Toolkit:common.button.login')}</Button>
                </form>
            </LockableArea>
        );
    }
}

LoginForm.defaultProps = {
    isLoading: false,
    onCompanyChange: () => {},
    company: null,
};

LoginForm.propTypes = {
    t: PropTypes.func.isRequired,
    loginError: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool,
    company: PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.string,
    }),
    onCompanyChange: PropTypes.func,
};

const mapStateToProps = state => Object.assign({}, {
    loginError: state.currentErrors.filter(error => error.type === LOGIN_FAILURE).length !== 0,
    isLoading: state.currentRequests.filter(request =>
        request.type === LOGIN_REQUEST).length > 0 || state.auth.isSignedIn,
    company: state.company,
});

const LoginFormContainer = connect(mapStateToProps)(LoginForm);

export default translate()(LoginFormContainer);
