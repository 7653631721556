import React from 'react';

import ChronoxMenu from './ChronoxMenu.jsx';

function ContentMenu(props) {
    const urls = app.store.getState().menu.map(item => Object.assign(
        {},
        item,
        { url: `/${(item.mode === 'index' ? '' : item.mode)}` },
    ));
    if (true) { // if in DEVELOPMENT!
        urls.push(
            { url: '/apitest', mode: 'apitest', title: 'Test Api' },
            { url: '/componentstest', mode: 'componentstest', title: 'Test Components' },
        );
    }

    return <ChronoxMenu items={urls} {...props} />;
}

export default ContentMenu;
