exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@charset \"UTF-8\";\n\n/**\n * This css document contains the necessary style for the login page of the Chronox app version 2.\n */\n\n._3B88LWSUtcZaDM7rWVcvf5 {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    left: 0;\n    top: 0;\n    z-index: 1000;\n    /*background-size: cover;*/\n    background: #EEE;\n    transition: all 0.5s ease;\n    overflow-x: hidden;\n}\n", ""]);

// exports
exports.locals = {
	"loginContainer": "_3B88LWSUtcZaDM7rWVcvf5"
};