import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import { ItemEditForm } from 'Toolkit';

class ReportDay extends React.Component {
    render() {
        const {
            day,
            t,
            onSubmit,
            onChange,
            quickReportingDays,
            projects,
            dayKey,
        } = this.props;

        // Get the day that matches the current ReportDay from the days.
        const currentStateDay = quickReportingDays.filter(d => d.date === day.date);
        // If the day exists in the state use its status as the current status
        // otherwise use the default status.
        const currentStatus = currentStateDay.length > 0 && currentStateDay[0].formValues.status ?
            currentStateDay[0].formValues.status :
            day.defaultItemValues.status;

        // For every field in the days configuration
        // add that field as a form field.
        const formFields = day.config.itemFields.map((field) => {
            // If there are several types only use the first one.
            const fieldType = field.type.constructor === Array ? field.type[0] : field.type;
            const fieldId = field.id;
            const fieldName = field.name;

            // Return the appropriate field type.
            switch (fieldType) {
                case 'status':
                    return (
                        <ItemEditForm.StatusField
                            key={`${dayKey}-${fieldId}`}
                            id={fieldId}
                            label={fieldName}
                            placeholder={`${t('field.choose')} ${fieldName.toLowerCase()}...`}
                            statuses={day.config.statusOptions}
                            defaultOption={day.defaultItemValues.status}
                        />
                    );
                case 'project':
                    return (
                        <ItemEditForm.ProjectField
                            key={`${dayKey}-${fieldId}`}
                            id={fieldId}
                            label={fieldName}
                            placeholder={`${t('field.choose')} ${fieldName.toLowerCase()}...`}
                            projects={
                                projects.map(project => ({
                                    code: project.id,
                                    name: project.name,
                                }))
                            }
                            defaultOption={day.defaultItemValues.project}
                            disabled={
                                field.onlyIfStatus.length > 0 &&
                                field.onlyIfStatus.indexOf(currentStatus) === -1
                            }
                        />
                    );
                case 'hours':
                    return (
                        <ItemEditForm.HoursField
                            key={`${dayKey}-${fieldId}`}
                            id={fieldId}
                            label={fieldName}
                            placeholder={`${fieldName}...`}
                            unit={field.unit}
                            minHours={0}
                            maxHours={day.reportingHours}
                            stepSize={field.step || 1}
                            defaultHour={day.defaultItemValues.hours || day.reportingHours}
                        />
                    );
                case 'time':
                    return (
                        <ItemEditForm.TimeField
                            key={`${dayKey}-${fieldId}`}
                            id={fieldId}
                            label={fieldName}
                            defaultValue="--:-- --"
                        />
                    );
                case 'options':
                    return (
                        <ItemEditForm.OptionsField
                            key={`${dayKey}-${fieldId}`}
                            id={fieldId}
                            label={fieldName}
                            placeholder={`${t('field.choose')} ${fieldName.toLowerCase()}...`}
                            options={
                                Object.keys(field.options).map(optionsKey => ({
                                    code: optionsKey,
                                    name: field.options[optionsKey].name,
                                }))
                            }
                            disabled={
                                field.onlyIfStatus.length > 0 &&
                                field.onlyIfStatus.indexOf(currentStatus) === -1
                            }
                        />
                    );
                case 'text':
                    return (
                        <ItemEditForm.TextField key={`${dayKey}-${fieldId}`} id={fieldId} label={fieldName} placeholder="" />
                    );
                case 'checkbox':
                    return (
                        <ItemEditForm.CheckboxField key={`${dayKey}-${fieldId}`} id={fieldId} label={fieldName} />
                    );
                case 'number':
                    return (
                        <ItemEditForm.NumberField key={`${dayKey}-${fieldId}`} id={fieldId} label={fieldName} placeholder="" />
                    );
                default:
                    return (
                        <ItemEditForm.InputField key={`${dayKey}-${fieldId}`} id={fieldId} label={fieldName} placeholder="" />
                    );
            }
        });

        const element = (
            // eslint-disable-next-line react/no-array-index-key
            <ItemEditForm key={dayKey} submitText={t('form.submitText')} onSubmit={formValues => onSubmit(day.date, formValues)} onChange={formValues => onChange(day.date, formValues)}>
                {formFields}
            </ItemEditForm>
        );

        return element;
    }
}

ReportDay.defaultProps = {
    onChange: () => console.warn('No onChange function passed down to ReportDay.'),
};

ReportDay.propTypes = {
    day: PropTypes.shape({
        config: PropTypes.shape({
            itemFields: PropTypes.array.isRequired,
            statusOptions: PropTypes.array.isRequired,
        }).isRequired,
    }).isRequired,
    dayKey: PropTypes.string.isRequired,
    quickReportingDays: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    projects: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    t: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onChange: PropTypes.func,
};

const mapStateToProps = state => Object.assign({}, {
    quickReportingDays: state.quickReporting.days,
});

const connectedComponent = connect(mapStateToProps)(ReportDay);

const translatedComponent = translate('Timesheet')(connectedComponent);
const finalComponent = translatedComponent;

export default finalComponent;
