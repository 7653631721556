import React from 'react';

import WorkShiftsBlock from 'App/Container/Ledger/WorkShiftsBlock.jsx';

const App = {
    Container: {
        Ledger: {
            WorkShiftsBlock,
        },
    },
};

function WorkShiftsSection() {
    const sectionStyle = {
        width: '100%',
        height: 'auto',
        padding: '0',
        margin: '0',
    };

    const element = (
        <div style={{ sectionStyle }}>
            <App.Container.Ledger.WorkShiftsBlock />
        </div>
    );

    return element;
}

class WorkShiftsTab extends React.Component {
    render() {
        const element = (
            <div>
                <WorkShiftsSection />
            </div>
        );

        return element;
    }
}

const finalComponent = WorkShiftsTab;

export default finalComponent;
