import React from 'react';
import PropTypes from 'prop-types';
import { TransitionablePortal, Message as SemanticMessage } from 'semantic-ui-react';

class Message extends React.Component {
    render() {
        const { message, negative, positive } = this.props;

        const element = (
            <TransitionablePortal open transition={{ animation: 'fade down', duration: 500 }}>
                <SemanticMessage
                    style={{
                        position: 'fixed',
                        top: '10px',
                        left: 0,
                        right: 0,
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        zIndex: 1000,
                        width: '30%',
                        minWidth: '300px',
                    }}
                    negative={negative}
                    positive={positive}
                >
                    <p>{message}</p>
                </SemanticMessage>
            </TransitionablePortal>
        );

        return element;
    }
}

Message.defaultProps = {
    negative: false,
    positive: false,
};

Message.propTypes = {
    message: PropTypes.string.isRequired,
    negative: PropTypes.bool,
    positive: PropTypes.bool,
};

const finalComponent = Message;

export default finalComponent;
