/**
 * Returns true if the passed in value is a number, and false otherwise.
 *
 * @param {*} value the value to check.
 */
export function isNumber(value) {
    return !Number.isNaN(value);
}

/**
 * Returns true if the passed in value is a positive number, and false otherwise.
 * 0 results in true.
 *
 * @param {*} value the value to check.
 */
export function isPositiveNumber(value) {
    if (!isNumber(value)) {
        return false;
    }

    // Convert the value to a number and
    // check if it is positive or negative.
    // 0 results in true.
    return +value >= 0;
}
