import React from 'react';
import PropTypes from 'prop-types';
import { Accordion, Form, Segment, Label } from 'semantic-ui-react';

class AccordionForm extends React.Component {
    render() {
        const { children, keys, titles } = this.props;
        const panels = React.Children.map(
            children,
            (child, index) => ({
                key: keys[index],
                title: {
                    content: (
                        <Label htmlFor="none" color={titles[index].color}>
                            {titles[index].text}
                            <Label.Detail>{titles[index].detail}</Label.Detail>
                        </Label>
                    ),
                },
                content: {
                    content: child,
                },
            }),
        );

        const element = (
            <Segment color="orange">
                <Accordion styled fluid exclusive={false} panels={panels} as={Form.Field} />
            </Segment>
        );

        return element;
    }
}

AccordionForm.propTypes = {
    children: PropTypes.arrayOf(PropTypes.element).isRequired,
    keys: PropTypes.arrayOf(PropTypes.string).isRequired,
    titles: PropTypes.arrayOf(PropTypes.shape({
        text: PropTypes.string.isRequired,
        detail: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired,
    })).isRequired,
};

export default AccordionForm;
