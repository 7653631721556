import React from 'react';
import { Button } from 'semantic-ui-react';

function ModeSwitcher(props) {
    const { options, active } = props;
    const buttons = [];

    for (let i = 0; i < options.length; i += 1) {
        buttons.push((
            <Button primary={i === active} onClick={options[i].onClick}>{options[i].title}</Button>
        ));
        if (i < options.length - 1) {
            buttons.push((<Button.Or />));
        }
    }
    console.log(buttons);
    return (
        <Button.Group>
            {buttons}
        </Button.Group>
    );
}

const finalComponent = ModeSwitcher;

export default finalComponent;
