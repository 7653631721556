import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import { AccordionForm, Info } from 'Toolkit';
import { quickReportDayChange } from '../../Actions';
import ReportDay from './ReportDay.jsx';

/**
 * This function transforms a date object into a
 * humanly readable date in the form: today, yesterday ... 1st, 2nd, 3rd ... 13th.
 * It uses the passed in dateTranslations object to do so.
 *
 * @param {Date} date the date to get a representation for.
 * @param {object} dateTranslations the translations to use for representing the days.
 *   The object should contain the following properties:
 *   * today
 *   * yesterday
 *   * tdby
 *   * st
 *   * nd
 *   * rd
 *   * th
 */
const getDateRepresentation = (date, dateTranslations) => {
    // The current date (day), used for comparison.
    const today = new Date().getDate();
    // The date (day) of yesterday, used for comparison.
    const yesterday = new Date(new Date().setDate(today - 1)).getDate();
    // The date (day) of the day before yesterday, used for comparison.
    const tdby = new Date(new Date().setDate(today - 2)).getDate();

    // The representation to return. It defaults
    // to the simple day-of-the-month of the date.
    let dateRepresentation = date.getDate();

    // Get the representation.
    switch (date.getDate()) {
        case today:
            dateRepresentation = dateTranslations.today;
            break;
        case yesterday:
            dateRepresentation = dateTranslations.yesterday;
            break;
        case tdby:
            dateRepresentation = dateTranslations.tdby;
            break;
        case 1:
            dateRepresentation = date.getDate() + dateTranslations.st;
            break;
        case 2:
            dateRepresentation = date.getDate() + dateTranslations.nd;
            break;
        case 3:
            dateRepresentation = date.getDate() + dateTranslations.rd;
            break;
        default:
            dateRepresentation = date.getDate() + dateTranslations.th;
            break;
    }

    // Return the representation.
    return dateRepresentation;
};

class QuickReportingBlock extends React.Component {
    constructor(props) {
        super(props);

        this.handleTimesheetEditFormSubmission = this.handleTimesheetEditFormSubmission.bind(this);
        this.handleTimesheetEditFormChange = this.handleTimesheetEditFormChange.bind(this);
    }

    handleTimesheetEditFormSubmission(date, formValues) {
        this.props.onDaySubmit(date, formValues);
    }

    handleTimesheetEditFormChange(date, formValues) {
        this.props.onDayChange(date, formValues);
    }

    render() {
        const {
            days,
            quickReportingProjects,
            id,
            t,
        } = this.props;

        // The dateTranslations object to be passed
        // to the getDateRepresentation call.
        const dateTranslations = {
            today: t('dateRepresentation.today'),
            yesterday: t('dateRepresentation.yesterday'),
            tdby: t('dateRepresentation.tdby'),
            st: t('dateRepresentation.st'),
            nd: t('dateRepresentation.nd'),
            rd: t('dateRepresentation.rd'),
            th: t('dateRepresentation.th'),
        };

        // For each day, create a title with
        // an associated text, detail and color.
        // The titles are used by AccordionForm.
        const titles = days.map(day => ({
            text: getDateRepresentation(day.date, dateTranslations),
            detail: day.summary.text,
            color: day.summary.hours > 0 ? 'blue' : 'orange',
        }));

        // Create a ReportDay for the first five days.
        const formRows = days.slice(0, 5).map(day => (
            <ReportDay
                dayKey={`ReportDay-${day.date.getTime()}`}
                key={`ReportDay-${day.date.getTime()}`}
                day={day}
                onSubmit={(date, formValues) =>
                    this.handleTimesheetEditFormSubmission(date, formValues)
                }
                onChange={(date, formValues) =>
                    this.handleTimesheetEditFormChange(date, formValues)
                }
                projects={day.projects.map(project => quickReportingProjects[project])}
            />
        ));

        // Create the keys for the rows.
        // The keys are used by AccordionForm.
        const formFieldKeys = formRows.map((row, index) => `formFieldKeys-${days[index].date.getTime()}`);

        let message = null;
        let detail = null;

        // If there are more that five days, create
        // a message informing the user about the remaining days.
        if (days.length > 5) {
            // The number of remaining days.
            const numberOfDays = days.length - 5;
            // The date of the earliest day.
            const from = days[numberOfDays - 1].date;
            // The date of the latest day.
            const to = days[0].date;
            // The textual representation of the earliest date's month.
            const fromMonth = t(`monthRepresentation.${from.getMonth() + 1}`);
            // The textual representation of the latest date's month.
            const toMonth = t(`monthRepresentation.${to.getMonth() + 1}`);
            // The humanly readable text representing the date of the earliest remaining day.
            const fromText = `${from.getDate()} ${fromMonth} ${from.getFullYear()}`;
            // The humanly readable text representing the date of the latest remaining day.
            const toText = `${to.getDate()} ${toMonth} ${to.getFullYear()}`;

            // Build the final message to display.
            // The message is the informational text explaining the situation.
            // The detail is the "parenthesized" details, which in this case
            // is a date span.
            const dateSpan = numberOfDays > 1 ? `${fromText} - ${toText}` : `${fromText}`;
            message = t('Timesheet:QuickReporting.message.missingMoreDaysText_days_period', { days: numberOfDays, period: dateSpan });
            //detail = dateSpan;
        }

        const element = (
            <div id={id}>
                <AccordionForm keys={formFieldKeys} titles={titles}>
                    {formRows}
                </AccordionForm>
                {message ? <Info message={message} detail={detail} /> : null}
            </div>
        );

        return element;
    }
}

QuickReportingBlock.defaultProps = {
    id: 'quickreportingblock',
};

QuickReportingBlock.propTypes = {
    days: PropTypes.arrayOf(PropTypes.shape({
        date: PropTypes.instanceOf(Date).isRequired,
        summary: PropTypes.shape({
            text: PropTypes.string.isRequired,
            hours: PropTypes.number.isRequired,
        }).isRequired,
        isComplete: PropTypes.bool.isRequired,
    })).isRequired,
    quickReportingProjects: PropTypes.shape({}).isRequired,
    onDaySubmit: PropTypes.func.isRequired,
    onDayChange: PropTypes.func.isRequired,
    id: PropTypes.string,
    t: PropTypes.func.isRequired,
};

const mapStateToProps = state => Object.assign({}, {
    quickReportingProjects: state.quickReporting.projects,
});

const mapDispatchToProps = dispatch => Object.assign({}, {
    onDayChange: (date, formValues) => dispatch(quickReportDayChange(date, formValues)),
});

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(QuickReportingBlock);
const translatedComponent = translate('Timesheet')(connectedComponent);
const finalComponent = translatedComponent;

export default finalComponent;
