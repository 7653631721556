/* eslint no-underscore-dangle: "off" */

import {
    setLoginBoxTab,
    logout,
    loginRequest,
    loginSuccess,
    loginFailure,
    loginFocus,
    setCompany,
    newVersionAlert,
    centralDialogOpen,
    centralDialogCloseAction,
} from './Actions';
import config from './config';
import i18n from './i18n.js';
import { centralDialogTypes } from './Reducers';

class UIHandler {
    constructor(reduxStore) {
        this._reduxStore = reduxStore;
    }


    /**
   * Event: New Version (build) of the client available
   *
   * Called when detecting that a new version of the client has been released, and
   * the user should or must update the page before continuing to use the application.
   *
   * The user should be informed about this, although if we know that no unsaved changes
   * has been made or that the user wouldn't be bothered (unlikely) we COULD reload
   * automatically.
   *
   * In the future we may distinguish between if the user MAY or MUST reload, depending on
   * whether current and new versions are compatible (minor or major change). If it is
   * deemed a minor and compatible change, forceReload parameter could be set to false.
   *
   * @param {boolean} forceReload
   */
    newClientVersionAvailable(forceReload = true) {
    // alert('New version of the client is available. You must reload!');
        this._dispatch(newVersionAlert(forceReload));
    }

    /**
   * Event: The user has clicked or initiated a function that is not available/implemented
   *
   * The user should be informed about this
   */
    featureNotAvailable() {
        this._dispatch(centralDialogOpen(centralDialogTypes.info, i18n.t('featureNotAvailableMessage'), i18n.t('featureNotAvailableHeader')));
    }

    /**
   * Event: The user has been signed out (possibly from a different tab).
   *
   * No more actions should be possible beyond this point, and the user should be encouraged
   * to go to the login screen.
   */
    hasBeenSignedOut() {
        this._dispatch(centralDialogOpen(centralDialogTypes.info, i18n.t('hasBeenSignedOutMessage'), i18n.t('hasBeenSignedOutHeader'), () => window.location.reload()));
    }

    /**
   * Event: The central dialog (whatever it is about) should be closed
   *
   * No callbacks or other event handlers are fired due to this
   */
    centralDialogClose() {
        this._dispatch(centralDialogCloseAction());
    }

    setCompany(company) {
        this._dispatch(setCompany(company));
    }

    setLoginBoxTab(tabIndex) {
        this._dispatch(setLoginBoxTab(tabIndex));
    }

    loginFocus() {
        this._dispatch(loginFocus());
    }

    login(username, companyId, password, inputMode) {
        this._dispatch(loginRequest());

        app.api.login(username, companyId, password, inputMode) // Call the api for a login
            .then((response) => {
                if (!response.error) {
                    /* Moved in from Api::login() */

                    // If the server suggested location should not be used
                    if (!config.useSuggestedLocation && app.store.getState().company) {
                        // Get the current location
                        const l = window.location;
                        // Get the id2 of the currently set company
                        const { id2 } = app.store.getState().company;
                        // If id2 exists in the current location,
                        // don't add it to the future location, otherwise do add it
                        const pathAdd = l.pathname.split('/').slice(-2)[0] === id2 ? '' : `${id2}/`;
                        // Intercept the server suggested location with the created one
                        response.location = window.location.href + pathAdd;
                    }

                    const cookieName = config.sessionCookieName;
                    const cookieValue = `sstring%253D${response.accessToken}`;
                    const cookieDomain = config.sessionCookieDomain;
                    const cookiePath = response.location.replace(/^.+:\/\/[^/]+/, '').split('?')[0]; // Remove https://www.chronox.se/ (ot http) and Remove possible query string
                    const cookieExpireAfter = config.sessionCookieExpiresAfterDays;
                    const cookieExpireNow = -1;
                    const date = new Date();

                    date.setTime(date.getTime() + (cookieExpireNow * 24 * 60 * 60 * 1000));
                    document.cookie = `${cookieName}=0; expires=${date.toGMTString()}; path=${cookiePath}`;

                    date.setTime(date.getTime() + (cookieExpireAfter * 24 * 60 * 60 * 1000));
                    if (cookieExpireAfter !== null) {
                        document.cookie = `${cookieName}=${cookieValue}; domain=${cookieDomain}; expires=${date.toGMTString()}; path=${cookiePath}`;
                    } else {
                        document.cookie = `${cookieName}=${cookieValue}; domain=${cookieDomain}; path=${cookiePath}`;
                    }


                    // Future maybe: App.storeSessionData('site_sid', data, 333);
                    console.log('Before window location.');
                    [window.location] = response.location.split('?'); // Remove possible query string
                    console.log('After window location');
                    const initialData = {
                        user: response.info.user,
                        accessToken: response.accessToken,
                        role: response.role,
                    };

                    this._dispatch(loginSuccess(initialData));
                } else {
                    throw new Error(response);
                }
            }).catch(() => {
                const errorObject = null;

                this._dispatch(loginFailure(errorObject));
            });
    }

    logout() {
        sessionStorage.lastPage = null;
        app.api.logout()
            .then((response) => {
                [window.location] = response.location.split('?');
                this._dispatch(logout());
            }).catch(() => {
            });
    }

    set reduxStore(value) {
        this._reduxStore = value;
    }

    _dispatch(action) {
        if (this._reduxStore !== null) {
            this._reduxStore.dispatch(action);

            if (window.postMessage && JSON) {
                const msgString = JSON.stringify({
                    type: 'dispatch',
                    action,
                });
                console.log(`postMessage(${msgString})`);
                window.postMessage(msgString, '*');
            }
        }
    }
}

export default UIHandler;
