import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import { Message, Loader } from 'Toolkit';
import { setQuickReportProjects } from '../../../Actions';
import QuickReportingBlock from '../../Timesheet/QuickReportingBlock.jsx';

class QuickReportingBlockContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            timesheetDays: null,
            daysFetchError: false,
            daySaveError: false,
            daySaveSuccess: false,
            daySaveActive: false,
        };

        this.handleQuickReportSubmission = this.handleQuickReportSubmission.bind(this);
        this.stateIsReady = this.stateIsReady.bind(this);
    }

    componentDidMount() {
        // Get the days to report for, and set them to state.timesheetDays.
        // The date strings (yy-mm-dd) must be converted to javascript Date objects,
        // in order for them to work with QuickReportingBlock.
        app.api.getDaysToReportFor()
            .then((response) => {
                this.props.onQuickReportProjectsChange(response.entities.Project);
                this.setState({
                    timesheetDays: response.rows.map(row =>
                        Object.assign({}, row, { date: new Date(row.date) })),
                });
            })
            .catch(() => this.setState({ daysFetchError: true }));
    }

    handleQuickReportSubmission(date, formValues) {
        this.setState({ daySaveActive: true });

        // Fetch the day that matches the passed in date.
        // There should only be one such instance, since
        // date is the primary key of each day.
        // But to be safe, we assume that there might
        // be duplicates.
        const day = this.state.timesheetDays.filter(tsDay => tsDay.date === date)[0];

        // Get the default form values.
        const defaultFormValues = day.defaultItemValues;

        // First use the default values
        // then override with override with the actual form values.
        const submitFormValues = Object.assign(
            {},
            defaultFormValues,
            formValues,
        );

        // Upload the submitted day.
        app.api.saveTimeSheetDay(date, submitFormValues)
            .then((response) => {
                // Get the updated day (submitted day with refreshed values),
                // and convert its date string to a javascript Date.
                const updatedDay = response.timesheetDay;
                updatedDay.date = new Date(updatedDay.date);

                // Get the existing local days and replace the
                // day that has the same date as the updatedDay.
                const updatedDays = this.state.timesheetDays.map((timesheetDay) => {
                    if (timesheetDay.date.getTime() === updatedDay.date.getTime()) {
                        return updatedDay;
                    }
                    return timesheetDay;
                });

                // Update the days, which will rerender
                // QuickReportingBlock with the newly
                // saved day. Also set a success flag
                // that will result in a success message
                // being rendered. Lastly, to make sure
                // that a possible old error message and loader
                // to reappear, set daySaveError and daySaveActive
                // to false.
                this.setState({
                    timesheetDays: updatedDays,
                    daySaveSuccess: true,
                    daySaveActive: false,
                    daySaveError: false,
                });
            })
            // If the save action fails,
            // remove success message and loader
            // and make error message appear.
            .catch(() => this.setState({
                daySaveError: true,
                daySaveSuccess: false,
                daySaveActive: false,
            }));
    }

    stateIsReady() {
        // If no state property is found to be null
        // the method returns true.
        let isReady = true;

        // If any of the state properties is null,
        // the function returns false.
        Object.keys(this.state).forEach((key) => {
            if (this.state[key] === null) {
                isReady = false;
            }
        });

        return isReady;
    }

    render() {
        const { t } = this.props;
        const {
            timesheetDays,
            daySaveError,
            daySaveSuccess,
            daySaveActive,
        } = this.state;

        if (this.stateIsReady()) {
            // The message to display if there was an error saving the day.
            const errorMessage = <Message negative message={t('daySaveError')} />;
            // The message to display when the day save was successful.
            const successMessage = <Message positive message={t('daySaveSuccess')} />;
            return (
                <div>
                    {daySaveError ? errorMessage : null}
                    {daySaveSuccess ? successMessage : null}
                    <Loader active={daySaveActive}>
                        <QuickReportingBlock
                            days={timesheetDays}
                            onDaySubmit={this.handleQuickReportSubmission}
                        />
                    </Loader>
                </div>
            );
        // If the days could not be fetched, display a message.
        } else if (this.state.daysFetchError) {
            return <Message negative message={t('daysFetchError')} />;
        }

        // If all else fail, show a loader.
        // This is the case, for instance,
        // while the days are being fetched.
        return <Loader active inline />;
    }
}

QuickReportingBlockContainer.propTypes = {
    onQuickReportProjectsChange: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => Object.assign({}, {
    onQuickReportProjectsChange: projects => dispatch(setQuickReportProjects(projects)),
});

const connectedComponent = connect(null, mapDispatchToProps)(QuickReportingBlockContainer);
const translatedComponent = translate('Timesheet')(connectedComponent);
const finalComponent = translatedComponent;

export default finalComponent;
