import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';

class Group extends React.Component {
    render() {
        const { children } = this.props;

        const element = (
            <Form.Group widths="equal">
                {children}
            </Form.Group>
        );
        return element;
    }
}

Group.propTypes = {
    children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

export default Group;
