import { SET_LEDGER, SET_GROUPED } from '../Actions';

export default (state = {}, action) => {
    switch (action.type) {
        case SET_LEDGER:
            return Object.assign({}, state, {
                current: action.ledgerId,
            });
        case SET_GROUPED:
            return Object.assign({}, state, {
                isGrouped: action.isGrouped,
            });
        default:
            return state;
    }
};
