exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@charset \"UTF-8\";\n\n/**\n * This css document contains the necessary style for the component Toolkit/LoginForm and Toolkit/LoginFormAdmin of the Chronox react ui toolkit v1.\n */\n._3l4znDqYotOx-T8vKFgnRq {\n\tmargin-bottom: 1em;\n}\n._3l4znDqYotOx-T8vKFgnRq input::-webkit-input-placeholder { /* Chrome/Opera/Safari */\n    color: rgba(100, 100, 100, 1) !important;\n}\n._3l4znDqYotOx-T8vKFgnRq input::-moz-placeholder { /* Firefox 19+ */\n    color: rgba(100, 100, 100, 1) !important;\n}\n._3l4znDqYotOx-T8vKFgnRq input:-ms-input-placeholder { /* IE 10+ */\n    color: rgba(100, 100, 100, 1) !important;\n}\n._3l4znDqYotOx-T8vKFgnRq input:-moz-placeholder { /* Firefox 18- */\n    color: rgba(100, 100, 100, 1) !important;\n}\n\n\n._3l4znDqYotOx-T8vKFgnRq input:focus::-webkit-input-placeholder { /* Chrome/Opera/Safari */\n    color: rgba(0, 0, 0, 1) !important;\n}\n._3l4znDqYotOx-T8vKFgnRq input:focus::-moz-placeholder { /* Firefox 19+ */\n    color: rgba(0, 0, 0, 1) !important;\n}\n._3l4znDqYotOx-T8vKFgnRq input:focus:-ms-input-placeholder { /* IE 10+ */\n    color: rgba(0, 0, 0, 1) !important;\n}\n._3l4znDqYotOx-T8vKFgnRq input:focus:-moz-placeholder { /* Firefox 18- */\n    color: rgba(0, 0, 0, 1) !important;\n}\n\n._3l4znDqYotOx-T8vKFgnRq div {\n  padding-bottom: 5px !important;\n}\n\n._3l4znDqYotOx-T8vKFgnRq img {\n  position: absolute !important;\n  right: 0 !important;\n}\n", ""]);

// exports
exports.locals = {
	"chronoxInput": "_3l4znDqYotOx-T8vKFgnRq"
};