import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Button, Icon } from 'semantic-ui-react';
import Slider from 'rc-slider';

const SliderWithTooltip = Slider.createSliderWithTooltip(Slider);

class HoursField extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.defaultHour,
            maxHours: props.maxHours,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleMoreHoursClick = this.handleMoreHoursClick.bind(this);
    }

    handleChange(value) {
        // Update the local state and
        // ItemEditForm with the updated value
        // of the field.
        const { id } = this.props;
        this.setState({ value });
        this.props.onChange(id, value);
    }

    handleMoreHoursClick(event) {
        // Make sure that the default event is not triggered,
        // as this may have unexpected behavior. It might, for example,
        // trigger a submission of the closest form.
        event.preventDefault();

        const newValue = this.state.value + 1;
        const newState = { value: newValue };

        // If the new value is more than the current max hours
        // set the newMaxHours to the new value.
        // Otherwise, just update the value
        if (newValue > this.state.maxHours) {
            newState.maxHours = newValue;
        }

        // Update the local state and
        // ItemEditForm with the updated value
        // of the field.
        const { id } = this.props;
        this.setState(newState);
        this.props.onChange(id, newValue);
    }

    render() {
        const {
            label,
            placeholder,
            unit,
            minHours,
            stepSize,
            defaultHour,
        } = this.props;
        const { value, maxHours } = this.state;
        const { handleChange } = this;

        // These methods handles the problem of different input
        // types representing the same value. They simple delegate
        // the slider and input changes to the actual change handler.
        const handleSliderChange = sliderValue => handleChange(sliderValue);
        const handleInputhange = (event, object) => handleChange(object.value);

        // If maxHours is not evenly divisible by stepSize, the following line
        // will adjust the max value of the slider to the value of the step
        // just after the max value
        const max = Math.ceil(maxHours / stepSize) * stepSize;

        const element = (
            <Form.Group widths="equal">
                <Form.Field width={12} label={label} control={SliderWithTooltip} min={minHours} max={max} defaultValue={defaultHour} step={stepSize} tipFormatter={v => `${v} ${unit}`} onChange={handleSliderChange} value={value} />
                <Form.Field
                    action={(
                        <Button color="blue" onClick={this.handleMoreHoursClick} icon>
                            <Icon name="plus" />
                        </Button>
                    )}
                    actionPosition="left"
                    width={4}
                    control={Input}
                    type="text"
                    placeholder={placeholder}
                    style={{ marginTop: '5px' }}
                    value={value}
                    onChange={handleInputhange}
                />
            </Form.Group>
        );
        return element;
    }
}

HoursField.defaultProps = {
    minHours: 0,
    maxHours: 8,
    stepSize: 1,
    defaultHour: 7,
    onChange: () => console.warn('No onChange function passed down to HoursField'),
};

HoursField.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    unit: PropTypes.string.isRequired,
    minHours: PropTypes.number,
    maxHours: PropTypes.number,
    stepSize: PropTypes.number,
    defaultHour: PropTypes.number,
    onChange: PropTypes.func,
};

export default HoursField;
