import React from 'react';
import PropTypes from 'prop-types';
import { Menu, Container } from 'semantic-ui-react';

import Tab from './Tab.jsx';
import Header from './Header.jsx';

class TabbedView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTabIndex: 0,
        };

        this.menuItemClickHandler = this.menuItemClickHandler.bind(this);
    }

    menuItemClickHandler(tabIndex) {
        this.setState({ activeTabIndex: tabIndex });
    }

    render() {
        const { activeTabIndex } = this.state;
        const { children } = this.props;

        let content = null;
        const menuChildren = children.filter(child => child.type.name === 'Tab');
        const menuItems = React.Children.map(
            menuChildren,
            (child, index) => {
                let active = false;

                if (index === activeTabIndex) {
                    content = child.props.children;
                    active = true;
                }

                return (
                    <Menu.Item
                        onClick={() => this.menuItemClickHandler(index)}
                        active={active}
                    >
                        {child.props.title}
                    </Menu.Item>
                );
            },
        );

        // Get the header child.
        // There can only ever be one header element,
        // therefore we simply take the first child with
        // the name 'Header'. Any additional children are
        // ignored.
        const headerChildren = children.filter(child => child.type.name === 'Header');
        const header = <Container>{headerChildren[0].props.children}</Container>;

        const element = (
            <div>
                {header}
                <Menu pointing>
                    {menuItems}
                </Menu>
                {content}
            </div>
        );

        return element;
    }
}

TabbedView.propTypes = {
    children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

TabbedView.Tab = Tab;
TabbedView.Header = Header;

export default TabbedView;
