import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Label, Button } from 'semantic-ui-react';
import { isPositiveNumber } from 'Toolkit/Utils/Number';

class NumberField extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: 0,
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleDecreaseValueClick = this.handleDecreaseValueClick.bind(this);
        this.handleIncreaseValueClick = this.handleIncreaseValueClick.bind(this);
    }

    handleInputChange(event, object) {
        const { id, onChange } = this.props;

        // Only allow positive number to be used,
        // and ignore anything else.
        if (isPositiveNumber(object.value)) {
            // Update the local state and
            // ItemEditForm with the updated value
            // of the field.
            this.setState({
                value: Number(object.value),
            });
            onChange(id, Number(object.value));
        }
    }

    handleDecreaseValueClick(event) {
        // Make sure that the default event is not triggered,
        // as this may have unexpected behavior. It might, for example,
        // trigger a submission of the closest form.
        event.preventDefault();

        // Decrease the value by one. Set minimum value to 0.
        this.handleInputChange(event, { value: Math.max(this.state.value -= 1, 0) });
    }

    handleIncreaseValueClick(event) {
        // Make sure that the default event is not triggered,
        // as this may have unexpected behavior. It might, for example,
        // trigger a submission of the closest form.
        event.preventDefault();

        // Increase the value by one.
        this.handleInputChange(event, { value: this.state.value += 1 });
    }

    render() {
        const {
            label,
            placeholder,
        } = this.props;

        const { value } = this.state;

        const { handleInputChange, handleDecreaseValueClick, handleIncreaseValueClick } = this;

        const element = (
            <Form.Field control={Input} label={label} labelPosition="right" type="text" placeholder={placeholder} onChange={handleInputChange} value={value}>
                {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                <Label as={Button} compact icon="minus" onClick={handleDecreaseValueClick} />
                <input />
                {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                <Label as={Button} compact icon="plus" onClick={handleIncreaseValueClick} />
            </Form.Field>
        );
        return element;
    }
}

NumberField.defaultProps = {
    onChange: () => console.warn('No onChange function passed down to NumberField'),
};

NumberField.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    onChange: PropTypes.func,
};

export default NumberField;
