import React from 'react';
import PropTypes from 'prop-types';
import { Dimmer, Loader as SemanticLoader } from 'semantic-ui-react';

class MyLoader extends React.Component {
    render() {
        const { active, inline, children } = this.props;

        let element = (
            <Dimmer.Dimmable blurring>
                {children}
                <Dimmer inverted active={active}>
                    <SemanticLoader />
                </Dimmer>
            </Dimmer.Dimmable>
        );

        if (inline) {
            element = <SemanticLoader active={active} inline="centered" size="medium" />;
        }

        return element;
    }
}

MyLoader.defaultProps = {
    active: false,
    inline: false,
    children: null,
};

MyLoader.propTypes = {
    active: PropTypes.bool,
    inline: PropTypes.bool,
    children: PropTypes.node,
};

const finalComponent = MyLoader;

export default finalComponent;
