/* eslint-disable */
import React from 'react';
import OldLinksWrapper from 'Toolkit/OldLinksWrapper.jsx';
import { translate } from 'react-i18next';
import i18n from 'i18n';

/**
 *
 *
 * https://github.com/reactjs/react-static-container
 *
 * <HTMLDocumentComponent name="" namespace="" params="client" arg1="" ... />
 */
class HTMLDocumentComponent extends React.Component {
    constructor(props)
    {
        super(props);
        this.state = {
            loaded: false,
            content: null
        };
    this._mounted = false;
    i18n.on('languageChanged', () => {
      this.forceUpdate();
    })
    }

	componentWillUnmount()
	{
		this._mounted = false;
		// Cancel any requests
		
	}

    componentDidMount()
    {
    const t = i18n.t;
		this._mounted = true;
        app.api.getOldComponent(this.componentName(), this.props).then((response) => {
            if (response.success) {
                if(this._mounted) {
                    this.setState({
                        loaded: true,
                        content: response.component.content,
                        linkStylesheets: response.component.linkStylesheets,
                        headStyleRules: response.component.headStyleRules,
                        headScripts: response.component.headScripts,
                        headJS: response.component.headJS,
                        headJSReady: response.component.headJSReady,
                    });
                }
            } else {
                if(this._mounted) {
                    this.setState({
                        loaded: false,
                        content: `<span className="error">${this.props.t('renderingComponentError')}</span>`,
                    });
                }
            }
        }).catch((response) => {
			if(this._mounted) {
	            this.setState({
	                loaded: false,
	                content: `<span className="error">${this.props.t('renderingComponentError')}</span>`,
	            });
			}
        });
    }
    componentDidUpdate()
    {
		if(this.state.loaded) {
			// NOTE: This should probably be divided; everything, except JSReady, could probably be done before rendering (eg DidMount)
			app.document.addComponent(this);
	    	if(this.state.headJSReady) { 
	    		//eval(this.state.headJSReady['']);
	    	}
		}
    }

    componentName()
    {
        var ns = this.componentNamespace();
        return (ns ? ns+"." : "") + this.__proto__.constructor.name;
    }

    componentNamespace()
    {
        return "Client.AdminWeb.Block";
    }

    render()
    {
   		//const script = this.state.headJSReady ? ("<script type='text/javascript'>function abcdefgh() { console.log('executing headJSReady'); " + this.state.headJSReady[''] + " } </script>"): null;
    	const content = <div dangerouslySetInnerHTML={{__html: this.state.content}} />;
    	return(
            <div style={{overflowX: 'auto', padding: '5px', paddingTop: '15px' }} className={"HTMLDocumentComponent-wrapper " + this.props.className + ' ' + (this.state.loaded ? 'is-loaded' : '')}>
            	<OldLinksWrapper>{content}</OldLinksWrapper>
            </div>
        );
    }

}

HTMLDocumentComponent.defaultProps = {
    t: key => i18n.t(key)
};

//HTMLDocumentComponent = translate()(HTMLDocumentComponent);

class OldComponent extends HTMLDocumentComponent
{
    componentName()
    {
        var ns = this.componentNamespace();
        return (ns ? ns+"." : "") + this.props.name;
    }

    componentNamespace()
    {
        if(this.props.namespace !== undefined) {
            return this.props.namespace;
        }
        else {
            console.log(super.componentNamespace());
            return super.componentNamespace();
        }
    }
}

export { HTMLDocumentComponent };
export default OldComponent;
