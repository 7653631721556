import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import { reactI18nextModule } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import config from './config';

function shouldChooseLanguage() {
    return (!config.languageDisabled || process.env.NODE_ENV !== 'production') && config.languageWhitelist.length > 1;
}

i18n
// Enables loading of external .json translation files
    .use(Backend)

// Use the react module
    .use(reactI18nextModule)

// Saves a cookie for the chosen language,
// notices the path ?setLng='code' and can notice the location of the user
    .use(LanguageDetector)

// Initialize the i18n instance
    .init({
        backend: {
            // The resolved path of the locales.
            // {{lng}} stands for the language code and {{ns}} stands for the desired namespace
            // (e.g. common.json, Container.json and Toolkit.json)
            loadPath: `${config.localesPrefix}locales/{{lng}}/{{ns}}.json`,
        },
        ns: ['Common'],
        defaultNS: 'Common',
        // next 3 lines were unsaved in my editor,
        // so i reintroduced them, not sure if they work [ra]
        whitelist: config.languageWhitelist,
        nonExplicitWhitelist: true,
        lng: (config.languageWhitelist.length === 1) ? config.languageWhitelist[0] : undefined,
        fallbackLng: config.languageWhitelist[0],
        // only provide language, no region specific locals like en-US, sv-SE
        load: 'languageOnly',
        // Should be set to false for the production version,
        // as it prints information in the browser console
        debug: false,
        react: {
            // Wait for all namespaces to be loaded until the component is rendered
            wait: true,
        },
    });

i18n.shouldChooseLanguage = shouldChooseLanguage;

export default i18n;
