import React from 'react';
import PropTypes from 'prop-types';

import { translate } from 'react-i18next';
import { connect } from 'react-redux';

import { ChronoxBox } from 'Toolkit';
import { Company } from 'Toolkit/Logo';
import LoginForm from 'Toolkit/LoginForm';
import LoginFormAdmin from 'Toolkit/LoginFormAdmin';
import Tab from 'Toolkit/Tabs/Tab';

function onTabChange(event, data) {
  app.ui.setLoginBoxTab(data.activeIndex);
}

/**
 * Refactoring notes
 * =================
 * 
 * Toolkit compontents should be devoid of application logic
 * 
 * 
 * Toolkit.LoginForm (rename)
 * - username
 * - password
 * - company
 * - companyOptions
 * - onLogin
 * - onCompanyChange
 * - onUsernameChange
 * - onPasswordChange
 * 
 * 
 */


/**
 * A LoginBox, is a simple box containing a LoginForm and CompanyLogo.
 * It has a transparent background,
 * and can be used in many contexts.
 */
class LoginBox extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      // Temporary company when no company exists in the state
      noCompanyCompany: null,
    };

    this.onCompanyChange = this.onCompanyChange.bind(this);
  }


  onCompanyChange(company, persistent = false) {
    this.setState({ noCompanyCompany: company });

     // -- don't permanently change before successful login
     if(persistent) {
       app.ui.setCompany(company);

     }
  }


  render() {
    const { t, company } = this.props;
    const titles = [t('xxx.reporter'), t('xxx.administrator')];
    const loginLinkStyle = {
      alignSelf: 'flex-end',
      position: 'absolute',
      top: 0,
      right: 0,
      color: 'rgba(191, 191, 191, 0.87)',
      width: '8rem',
      textAlign: 'right',
      margin: 'auto',
      fontSize: '0.9rem',
      paddingLeft: '15px',
    };
    const companyLogoStyle = {
      maxHeight: '50px',
      maxWidth: '60%',
      display: 'block',
      float: 'left',
    };

    // The element that will be rendered.
    // Note how all components used in this component, are from the custom toolkit.
    const forCompanyElement = (
      <ChronoxBox>
        <ChronoxBox.Row>
          <div style={{ height: '75px' }}><Company company={company} style={companyLogoStyle} /></div>
          <a style={loginLinkStyle} href={this.props.urlPrefix}>{t('openLoginLinkText')}</a>
        </ChronoxBox.Row>
        <ChronoxBox.Row>
          <Tab titles={titles} onTabChange={onTabChange} minHeight="15em">
            <LoginForm onCompanyChange={this.onCompanyChange} />
            <LoginFormAdmin onCompanyChange={this.onCompanyChange} />
          </Tab>
        </ChronoxBox.Row>
      </ChronoxBox>
    );

    const noCompanyElement = (
      <ChronoxBox>
        <ChronoxBox.Row>
          <div style={{ height: '75px' }}>
            <Company company={this.state.noCompanyCompany} style={companyLogoStyle} />
          </div>
        </ChronoxBox.Row>
        <ChronoxBox.Row>
          <Tab titles={titles} onTabChange={onTabChange} minHeight="15em">
            <LoginForm onCompanyChange={this.onCompanyChange} />
            <LoginFormAdmin onCompanyChange={this.onCompanyChange} />
          </Tab>
        </ChronoxBox.Row>
      </ChronoxBox>
    );

    return this.props.company === null ? noCompanyElement : forCompanyElement;
  }
}

LoginBox.propTypes = {
  t: PropTypes.func.isRequired,
  company: PropTypes.shape({}),
  urlPrefix: PropTypes.string.isRequired,
};

LoginBox.defaultProps = {
  company: null,
};

const mapStateToProps = state => Object.assign({}, {
  company: state.company,
  urlPrefix: state.urlPrefix,
});

const LoginBoxContainer = connect(mapStateToProps)(LoginBox);

export default translate()(LoginBoxContainer);
