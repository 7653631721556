import React from 'react';
import PropTypes from 'prop-types';

import OldComponent from 'Toolkit/OldComponent';

function CheckInOutBlock(props) {
    const { ledgerId, isGrouped } = props;

    return <OldComponent namespace="Client.AdminWebV2.Components" name="App.Ledger.CheckInOutBlock" ledger={ledgerId} grouped={isGrouped ? 1 : 0} />;
}

CheckInOutBlock.propTypes = {
    ledgerId: PropTypes.string.isRequired,
    isGrouped: PropTypes.bool.isRequired,
};

const finalComponent = CheckInOutBlock;

export default finalComponent;
