import React from 'react';
import PropTypes from 'prop-types';
import { Dimmer, Loader } from 'semantic-ui-react';

/**
 * Toolkit / Wrappers / LoackableArea
 * 
 * When active will "dim" or disable its content and provide progress/activity indication.
 * 
 * PROPS:
 * - active
 * 
 * NOTES:
 * - Could be extended with a "error" property, if eg the loading failed, possibly with retry.
 */
class LockableArea extends React.Component {

    render() {
        return (
            <Dimmer.Dimmable blurring>
                <Dimmer inverted active={this.props.active}>
                    <Loader />
                </Dimmer>
                {this.props.children}
            </Dimmer.Dimmable>
        );
    }

}

LockableArea.propTypes = {
    active: PropTypes.bool,
};

LockableArea.defaultProps = {
    active: false,
};

export default LockableArea;
