import React from 'react';

import SnapshotTable from 'App/Container/Ledger/SnapshotTable.jsx';

const App = {
    Container: {
        Ledger: {
            SnapshotTable,
        },
    },
};

function SnapshotSection() {
    const sectionStyle = {
        width: '100%',
        height: 'auto',
        padding: '0',
        margin: '0',
    };

    const element = (
        <div style={{ sectionStyle }}>
            <App.Container.Ledger.SnapshotTable />
        </div>
    );

    return element;
}

class SnapshotTab extends React.Component {
    render() {
        const element = (
            <div>
                <SnapshotSection />
            </div>
        );

        return element;
    }
}

const finalComponent = SnapshotTab;

export default finalComponent;
