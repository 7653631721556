import React from 'react';
import i18n from 'i18n';
import QuickReportingBlock from 'App/Container/Timesheet/QuickReportingBlock';
import { LanguageChooser } from 'Toolkit/Utils/LanguageChooser';

class TestPage extends React.Component {
    constructor(props) {
        super(props);
        i18n.changeLanguage('sv');

        this.state = {
            currentLanguage: i18n.language,
        };

        this.handleLanguageChange = this.handleLanguageChange.bind(this);
    }

    handleLanguageChange(language) {
        i18n.changeLanguage(language);
        this.setState({ currentLanguage: language });
    }

    render() {
        const { currentLanguage } = this.state;
        const { handleLanguageChange } = this;

        const state = app.store.getState();
        const currentCompany = state.company ? state.company.name : 'No company';
        const currentUserName = state.user && state.user.name !== '' ? state.user.name : 'No user';

        const element = (
            <div style={{ padding: '10px' }}>
                <h1>Chronox components testing area</h1>
                <span>Current company: {currentCompany}</span>
                <br />
                <span>Current user: {currentUserName}</span>
                <h2>Toolkit.Utils.LanguageChooser</h2>
                <LanguageChooser options={[{ countryCode: 'se', languageCode: 'sv' }, { countryCode: 'gb', languageCode: 'en' }]} value={currentLanguage} onChange={handleLanguageChange} />
                <h2>App.Container.Timesheet.QuickReportingBlock</h2>
                <QuickReportingBlock />
            </div>
        );
        return element;
    }
}

export default TestPage;
