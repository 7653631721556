import React from 'react';

import HeaderBlock from 'App/Container/Ledger/HeaderBlock.jsx';

const App = {
    Container: {
        Ledger: {
            HeaderBlock,
        },
    },
};

function HeaderSection() {
    const sectionStyle = {
        width: '100%',
        height: 'auto',
        padding: '0',
        margin: '0',
    };

    const element = (
        <div style={{ sectionStyle }}>
            <App.Container.Ledger.HeaderBlock />
        </div>
    );

    return element;
}

export default HeaderSection;
