import React from 'react';
import PropTypes from 'prop-types';
import { Form, Button } from 'semantic-ui-react';

import Group from './Group.jsx';
import CheckboxField from './CheckboxField.jsx';
import StatusField from './StatusField.jsx';
import ProjectField from './ProjectField.jsx';
import HoursField from './HoursField.jsx';
import TimeField from './TimeField.jsx';
import TextField from './TextField.jsx';
import OptionsField from './OptionsField.jsx';
import InputField from './InputField.jsx';
import NumberField from './NumberField.jsx';

class ItemEditForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            formValues: {},
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleFieldChange = this.handleFieldChange.bind(this);
    }

    handleSubmit() {
        this.props.onSubmit(this.state.formValues);
    }

    handleFieldChange(fieldId, fieldValue) {
        // Add the new value to the existing values.
        // The values are stored with the id of the field
        // as a key, to enable dynamic handling of fields.
        // The ItemEditForm does not have to know the specific
        // fields in advance.
        const oldFormValues = this.state.formValues;
        const newFormValues = Object.assign({}, oldFormValues, { [fieldId]: fieldValue });

        // Notify the parent component and update the state
        // with the new values.
        this.props.onChange(newFormValues);
        this.setState({
            formValues: newFormValues,
        });
    }

    render() {
        const { children, submitText } = this.props;
        const { handleFieldChange } = this;

        // Separate the groups and the individual items.
        const groupChildren = children.filter(child => child.type.name === 'Group');
        const fieldChildren = children.filter(child => child.type.name !== 'Group');

        // Intercept the group children, adding an onChange prop to the children of each group.
        // This is used to retrieve the values from the fields.
        // Note that the group children are not intercepted, but their children.
        // Hence the call to map within map.
        const groupChildrenIntercepted = groupChildren.map(child =>
            child.props.children.map(nestedChild =>
                Object.assign(
                    {},
                    nestedChild,
                    {
                        props: Object.assign(
                            {},
                            nestedChild.props,
                            { onChange: handleFieldChange },
                        ),
                    },
                )));

        // Intercept the field children, adding an onChange prop.
        // This is used to retrieve the values from the fields.
        const fieldChildrenIntercepted = fieldChildren.map(child => Object.assign(
            {},
            child,
            {
                props: Object.assign({}, child.props, { onChange: handleFieldChange }),
            },
        ));

        // Merge the group children and the field children.
        const finalChildren = [].concat(groupChildrenIntercepted, fieldChildrenIntercepted);

        const element = (
            <Form onSubmit={this.handleSubmit}>
                {finalChildren}
                <br />
                <Button color="blue" fluid type="submit">{submitText}</Button>
            </Form>
        );
        return element;
    }
}

// Define the fields that may be used with this form.
ItemEditForm.Group = Group;
ItemEditForm.CheckboxField = CheckboxField;
ItemEditForm.StatusField = StatusField;
ItemEditForm.ProjectField = ProjectField;
ItemEditForm.HoursField = HoursField;
ItemEditForm.TimeField = TimeField;
ItemEditForm.TextField = TextField;
ItemEditForm.OptionsField = OptionsField;
ItemEditForm.InputField = InputField;
ItemEditForm.NumberField = NumberField;

ItemEditForm.defaultProps = {
    onChange: () => console.warn('No onChange function passed down to ItemEditForm'),
};

ItemEditForm.propTypes = {
    children: PropTypes.arrayOf(PropTypes.element).isRequired,
    submitText: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
};

export default ItemEditForm;
