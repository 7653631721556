/* eslint import/first: "warn" */

// Important! The config (which sets public path) must be imported first!!!
import config from './config';
// import './Polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import $ from 'jquery';
import { createStore } from 'redux';
import { Provider } from 'react-redux';

import ScreenSignIn from 'Page/ScreenSignIn'; // eslint-disable-line

import i18n from './i18n';
import { App, ReactApp } from './App';
import chronoxReducer from './Reducers';
import { pageLoad, languageChange } from './Actions';
import Logger from './Logger';

// Hides the loading
function hideLoading() {
    $('body').removeClass('loading');
    if ($('.loadingOverlay:visible').length) {
        $('.loadingOverlay').hide();
    }
}

// Gets the access token. This function helps to simplify the fetching of
// the access token, since it has several sources of truth.
function getAccessToken() {
    // These rows decode the cookie and finds the right access token
    const cookie = decodeURIComponent(document.cookie);
    const cookieList = cookie.split(';');
    const siteSids = cookieList.filter(value => value.includes(config.sessionCookieName) === true);
    const siteSid = siteSids ? siteSids[siteSids.length - 1] : null;
    const accessToken = siteSid ? siteSid.split('=').pop() : null;

    // Try to use the access token from the config variable,
    // otherwise use the cookie version as a fallback
    return config.auth.accessToken || accessToken;
}

// Initializes the application.
function init(appInstance) {
    const app = appInstance;

    // Creates a custom log function that is avaiable in all react component,
    // by calling this.log. It conveniently logs the components name along with passed
    // in parameters.
    React.Component.prototype.log = function log(...args) {
        console.log(`----- ${this.constructor.name} ==> `, ...args); // eslint-disable-line
    };

    if (app.store.getState().auth.isSignedIn) {
        const userArea = $('#userArea');
        const fetchedByPost = userArea.length !== 0;

        // If the page was fetched using a post request (Ex. "Ny användare" or "Spara")
        if (fetchedByPost) {
            // Save the relevant page content
            app.postContent = userArea;
            app.postContent.find('.loadingOverlay').remove();
            // Empty the page (which produces a blank document that react can use)
            $('#container').remove();

            // Get the possible path to render from localstorage (this is set in on of the pages)
            const path = sessionStorage.goToAfterPost;
            // If the path has been explicitly set, the /p route should not be loaded
            if (path) {
                // Set the path to the one saved in local storage
                window.history.replaceState({}, window.title, app.linkPrefix + path);
            } else {
                // Go to .../p, which is recognized by the react app
                window.history.replaceState({}, window.title, `${app.linkPrefix}p`);
            }
        }

        // The element to be rendered at the base (i.e. the top most element)
        const appElement = (
            <Provider store={app.store}>
                <I18nextProvider i18n={i18n}>
                    <BrowserRouter basename={app.linkPrefix}>
                        <ReactApp />
                    </BrowserRouter>
                </I18nextProvider>
            </Provider>
        );
        // Render the app element mounting it on the app div (i.e. render the entire app)
        ReactDOM.render(appElement, document.getElementById('app'));

        // If The user is not signed in, the sign in screen is rendered
    } else {
        $('body').css('background-color', '#395169');
        const signInScreenElement = (
            <Provider store={app.store}>
                <I18nextProvider i18n={i18n}>
                    <ScreenSignIn />
                </I18nextProvider>
            </Provider>
        );
        ReactDOM.render(signInScreenElement, $('body > div')[0]);
    }

    // Hide the loading screen, since the app is rendered
    hideLoading();
}

// Initialize the custom logger
const logger = new Logger();
logger.interceptConsole();

// jQuery Fix
if (window.jQuery === undefined) {
  console.warn('jQuery not detected; aliasing WebPack internal to global'); // eslint-disable-line
    window.jQuery = $;
    window.$ = $;
}

// Create a new app instance, which holds the api and state
const app = new App();
// Create the redux store
const store = createStore(chronoxReducer);

// Update the redux state on every language change
i18n.on('languageChanged', language => store.dispatch(languageChange(language)));

// Store the handlers in the app instance
app.ui.reduxStore = store;
app.store = store;
app.accessToken = getAccessToken();
app.logger = logger;

// If the app is in production
if (process.env.NODE_ENV === 'production') {
    // Nothing to do here right now
} else {
    // Notify the developer
  console.warn('App in development mode.'); // eslint-disable-line
}

// If we are provided with the state from the server, we initialize from that.
// Otherwise, if we have an accessToken (from state, cookie, config),
// check if it is valid and get "info".
if (window.appState) {
    // Although, it could be an idea to verify that the accessToken is valid?
    store.dispatch(pageLoad(config));
    init(app);
} else if (app.accessToken) {
    app.api.getInfo().then((response) => {
        const data = Object.assign(
            {},
            config,
            response,
            { auth: { accessToken: app.accessToken } },
        );
        store.dispatch(pageLoad(data));
    }).catch(() => {
        store.dispatch(pageLoad(config));
    }).then(() => init(app));
} else { // We're clean, just do a clean initialization
    store.dispatch(pageLoad(config));
    init(app);
}
