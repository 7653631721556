import React from 'react';
import { Label, Icon } from 'semantic-ui-react';

export default function Info(props) {
    const { message, detail } = props;

    const element = (
        <Label id="none" htmlFor="none">
            <Icon name="info" />
            {message}
            <Label.Detail>{detail}</Label.Detail>
        </Label>
    );

    return element;
}
