import React from 'react';
import { Grid } from 'semantic-ui-react';
import { View } from 'Toolkit';
import ReportsToSubmitTable from 'App/ReportsToSubmitTable';

/**
 * Page: Report (for employee)
 */
class PageReport extends React.Component {
  render() {
    const element = (
      <View>
        <Grid stackable columns={2}>
          <Grid.Column width={16}>
            <h2 className="forBox">Rapporter</h2>
            <ReportsToSubmitTable />
          </Grid.Column>
        </Grid>
      </View>
    );

    return element;
  }
}

export default PageReport;
