import React from 'react';
import PropTypes from 'prop-types';
import StylePropType from 'react-style-proptype';
import { Image } from 'semantic-ui-react';

/**
 * The company logo can be used in order to insert the company logo of the currently logged in user.
 */
export default function CompanyLogo(props) {
  const state = app.store.getState();
  const { style } = props;

  const company = props.company || state.company;
  const name = company ? company.name : '';
  
  if (company && company.logoUrl) {
    return (
      <Image src={company.logoUrl} alt={name} style={style} />
    );
  }

  return (
    <span className="nologo">{name}</span>
  );
}

CompanyLogo.defaultProps = {
  style: {},
};

CompanyLogo.propTypes = {
  style: StylePropType,
  company: PropTypes.shape({
    name: PropTypes.string,
    logoUrl: PropTypes.string,
  }),
};
