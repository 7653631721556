import React from 'react';
import { Input, Select, Button } from 'semantic-ui-react';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';

const defaultOptions = {
    filter: '',
    select: {},
};

const selectOptions = [
    {
        text: 'GET',
        value: 'GET',
    },
    {
        text: 'POST',
        value: 'POST',
    },
];

class PageApiTest extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            method: '',
            type: 'GET',
            params: defaultOptions,
            result: {},
        };

        this.handleMethodChange = this.handleMethodChange.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.handleParamsChange = this.handleParamsChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleMethodChange(event, { value }) {
        this.setState({ method: value });
    }

    handleTypeChange(event, { value }) {
        this.setState({ type: value });
    }

    handleParamsChange({ jsObject }) {
        this.setState({ params: jsObject });
    }

    handleSubmit() {
        const { method, type, params } = this.state;
        app.api.call(method, type, params)
            .then(message => this.setState({ result: message }))
            .catch(error => this.setState({ result: error }));
    }

    render() {
        const {
            handleMethodChange,
            handleTypeChange,
            handleParamsChange,
            handleSubmit,
        } = this;
        const {
            method,
            type,
            params,
            result,
        } = this.state;

        const element = (
            <div style={{ padding: '20px' }}>
                <Button primary onClick={handleSubmit}>Submit</Button>
                <br />
                Method:
                <br />
                <Input placeholder="e.g. reports/timesheets/days" value={method} onChange={handleMethodChange} />
                <br />
                Type:
                <br />
                <Select placeholder="Choose type..." options={selectOptions} value={type} onChange={handleTypeChange} />
                <br />
                Params:
                <JSONInput
                    id="params"
                    placeholder={params}
                    locale={locale}
                    height="400px"
                    onChange={handleParamsChange}
                />
                <br />
                Results (view only):
                <JSONInput
                    id="result"
                    placeholder={result}
                    locale={locale}
                    height="400px"
                    viewOnly
                    theme="light_mitsuketa_tribute"
                />
            </div>
        );
        return element;
    }
}

export default PageApiTest;
