exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1W7XxRgc28a9OcLUq9ZAYd {\n    border: none;\n    margin-bottom: 3px;\n    margin-left: 8px;\n    font-weight: normal;\n    font-size: 1.1em;\n    margin-top: 2em;\n    color: #E88341;\n}\n\n._2f0azBNMo8G1K7RwAD7PvQ {\n    border: none;\n    margin-bottom: 3px;\n    margin-left: 8px;\n    font-weight: normal;\n    font-size: 1.3em;\n    margin-top: 2em;\n    color: #E88341;\n}\n\n._21YM5Met4xtM-fPdwXhqpk {\n    border: none;\n    margin-bottom: 3px;\n    margin-left: 8px;\n    font-weight: normal;\n    font-size: 1.5em;\n    margin-top: 2em;\n    color: #E88341;\n}\n", ""]);

// exports
exports.locals = {
	"small": "_1W7XxRgc28a9OcLUq9ZAYd",
	"medium": "_2f0azBNMo8G1K7RwAD7PvQ",
	"large": "_21YM5Met4xtM-fPdwXhqpk"
};