import PropTypes from 'prop-types';

function Tab() {
    return null;
}

Tab.propTypes = {
    title: PropTypes.string.isRequired,
};

export default Tab;
