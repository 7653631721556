/**
 * This file contains and exports the action types used in redux.
 * The action types represent all actions that are possible in the chronox app.
 */

// Signifies that the page has loaded
export const PAGE_LOAD = 'PAGE_LOAD';

// Signifies that the login window gains focus
export const LOGIN_FOCUS = 'LOGIN_FOCUS';

// Adds a login request instance
export const LOGIN_REQUEST = 'LOGIN_REQUEST';

// Logs in the user (sets up the ui)
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

// Adds a login failure instance
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

// Logs out the user (clears the ui)
export const LOGOUT = 'LOGOUT';

// Changes the currently selected tab of the login box
export const SET_LOGIN_BOX_TAB = 'SET_LOGIN_BOX_TAB';

// Changes the currently used company (company of the user)
export const SET_COMPANY = 'SET_COMPANY';

// Changes the configuration of the current menu
export const SET_MENU = 'SET_MENU';

// Sets the html classes to use on the body dom element
export const SET_BODY_CLASSES = 'SET_BODY_CLASSES';

// Sets the visibility status of the languge dropdown in the app menu
export const SET_LANGUAGE_DROPDOWN_STATUS = 'SET_LANGUAGE_DROPDOWN_STATUS';

// Signifies that the button that opens/closes the sidebar menu that is visible on smaller devices,
// has been clicked.
export const SIDEBAR_CLICK = 'SIDEBAR_CLICK';

// Signifies that the page/route of the app has changed
export const PAGE_CHANGE = 'PAGE_CHANGE';

// Signifies that the header of the application has been clicked
export const CLICK_ON_HEADER = 'CLICK_ON_HEADER';

// Signifies that the main content area of the application has been clicked
export const CLICK_ON_CONTENT = 'CLICK_ON_CONTENT';

// Signifies that a new version has been made available and that the application,
// wants to alert this to the user.
export const NEW_VERSION_ALERT = 'NEW_VERSION_ALERT';

// When showing the central modal dialog. Params: dialog
export const CENTRAL_DIALOG_OPEN = 'CENTRAL_DIALOG_OPEN';

// When closing the central modal dialog
export const CENTRAL_DIALOG_CLOSE = 'CENTRAL_DIALOG_CLOSE';

// Signifies that the language has been changed
export const LANGUAGE_CHANGE = 'LANGUAGE_CHANGE';

// Signifies that a reporting day form in the quick report block has changed its values
export const QUICK_REPORT_DAY_CHANGE = 'QUICK_REPORT_DAY_CHANGE';

// Sets the projects available to the quick report block
export const SET_QUICK_REPORT_PROJECTS = 'SET_QUICK_REPORT_PROJECTS';

// Sets the currently active ledger
export const SET_LEDGER = 'SET_LEDGER';

// Decides if the currently active ledger should have the grouped mode enabled
export const SET_GROUPED = 'SET_GROUPED';
