/* eslint no-underscore-dangle: "off",
          class-methods-use-this: "off",
          no-eval: "warn",
          no-bitwise: "warn",
          no-console: "off",
          react/no-danger: "warn",
          import/no-unresolved: "off",
          import/extensions: "off"
*/

import React from 'react';
import PropTypes from 'prop-types';
import { Route, withRouter } from 'react-router-dom';
import { createLocation } from 'history';
import { Sidebar, Modal, Button } from 'semantic-ui-react';
import $ from 'jquery';
import { connect } from 'react-redux';

import OldView from 'Toolkit/OldView';
import OldLinksWrapper from 'Toolkit/OldLinksWrapper';
import QueryRoute from 'Toolkit/QueryRoute';
import QueryParser from 'Toolkit/QueryParser';
import PageOverview from 'Page/PageOverview';
import PageLedgers from 'Page/PageLedgers';
import PageReports from 'Page/PageReports';
import PageEmployee from 'Page/PageEmployee';
import PageEmployeePage from 'Page/PageEmployeePage';
import PageEmployees from 'Page/PageEmployees';
import PageProjects from 'Page/PageProjects';
import PageUsers from 'Page/PageUsers';
import PageTools from 'Page/PageTools';
import PageSettings from 'Page/PageSettings';
import PageTimereport from 'Page/PageTimereport';
import PageWorkReport from 'Page/PageWorkReport';
import PageProject from 'Page/PageProject';
import PageUser from 'Page/PageUser';
import PageWorkOrderConfig from 'Page/PageWorkOrderConfig';
import PageWorkReportConfig from 'Page/PageWorkReportConfig';
import PageExport from 'Page/PageExport';
import PageReport from 'Page/PageReport';
import PageReportWorkReport from 'Page/PageReportWorkReport';
import PageReportOverview from 'Page/PageReportOverview';
import PageApiTest from 'Page/Test/PageApiTest';
import TestPage from 'Page/Test/TestPage';
import deviceTestCss from 'css/DeviceTest.css';

import Page from 'Page';

import ContentHeader from './ContentHeader';
import ContentMenu from './ContentMenu';
import ContentSubMenu from './ContentSubMenu';
import Api from './Api';
import UIHandler from './UIHandler';
import config from './config';
import { setBodyClasses, pageChange, clickOnContent } from './Actions';
import styles from 'css/App.css';
import { centralDialogTypes } from 'reducers';

class AppDocument {
    constructor() {
        this.addComponent = this.addComponent.bind(this);
        this.addStylesheet = this.addStylesheet.bind(this);
        this.addStyleRules = this.addStyleRules.bind(this);
        this.addJS = this.addJS.bind(this);
        this.addJSReady = this.addJSReady.bind(this);
        this._executeReadyScripts = this._executeReadyScripts.bind(this);
        this._scriptCompletedLoading = this._scriptCompletedLoading.bind(this);

        this.preloaded = {
            scriptsSrc: [],
            scriptsTag: [],
        }; // should be initialized to appState.preload;
        this._addedJS = [];
        this._scriptsLoading = 0;
        this._scriptsTags = [];
        this._scriptsOnReady = [];

        this._addedJS.push(this._hashText('//www.google.com/jsapi'));
        this._addedJS.push(this._hashText(config.buildFilePath));
        if (config.buildFilePath !== config.productionBuildFilePath) {
            this._addedJS.push(this._hashText(config.productionBuildFilePath));
        }
    }

    setPage(pageComponent) {
        console.log(`AppDocument: Setting page: ${pageComponent}`);
        app.store.dispatch(setBodyClasses(pageComponent.state.classes));
    }

    /**
   * Rather *added* component, call after inserting/updating the content
   */
    addComponent(component) {
        component.state.linkStylesheets.forEach(css => this.addStylesheet(css));
        component.state.headStyleRules.forEach(css => this.addStyleRules(css));
        component.state.headScripts.forEach(script => this.addScript(script));
        component.state.headJS.forEach(js => this.addJS(js));
        if (component.state.headJSReady instanceof Array) {
            component.state.headJSReady.forEach(js => this.addJSReady(js));
        } else if (component.state.headJSReady instanceof Object) {
            Object.values(component.state.headJSReady).forEach(js => this.addJSReady(js));
        }
    }

    addStylesheet(css) {
        const { href } = css;
        if ($('head link[rel=stylesheet]').filter(() => {
            const existingHref = $(this).attr('href');
            if (existingHref === href) {
                return true;
            }
            return false;
        }).length === 0) {
            console.log(`adding stylesheet: ${href}`);
            $('<link/>', {
                rel: 'stylesheet',
                type: 'text/css',
                href,
                media: css.media,
                title: css.title,
            }).appendTo('head');
        }
    }
    addStyleRules(css) {
        console.log(`adding style rules: ${css.substr(0, 30)}...`);
        $(`<style>${css}</style>`).appendTo('head');
    }
    addScript(script) {
        if (script instanceof Object) {
            const { src } = script;
            const hash = this._hashText(src);
            if (this._addedJS.indexOf(hash) === -1) {
                // console.log(this.preloaded.scriptsSrc);
                if (this.preloaded.scriptsSrc.indexOf(src) !== -1) {
                    // Already loaded in original HTML, ignore...
                    // console.log("Script " + src + " was already loaded");
                } else if ($('script[src]').filter((index, element) => {
                    let existingSrc = $(element).attr('src');
                    existingSrc = existingSrc.replace(/\?\d+$/, '');

                    if (existingSrc === src) {
                        return true;
                    }
                    return false;
                }).length === 0) {
                    this._addedJS.push(hash);
                    console.log(`adding script: ${src}`);
                    this._scriptsLoading += 1;
                    const self = this;
                    window.$.ajaxSetup({ cache: true });
                    window.$.getScript(src, () => {
                        window.$.ajaxSetup({ cache: true });
                        self._scriptCompletedLoading();
                    });
                }
            }
        } else if (typeof script === 'string') {
            const shouldIgnore = script.includes('app-document-ignore');
            if (!shouldIgnore) {
                // This may either be <script src></script> or <script>someCode;</script>
                console.log(`adding script (tag): ${script.substr(0, 60)}...`);
                this._scriptsTags.push(script);
            }
        } else {
            console.warn('unknown script type');
        }
    }

    addJS(js) {
        const hash = this._hashText(js);
        if (this._addedJS.indexOf(hash) === -1) {
            this._addedJS.push(hash);
            console.log(`adding javascript: ${js.substr(0, 60)}...`);
            this._scriptsOnReady.push(js);
            this._executeReadyScripts();
        }
    }


    addJSReady(js) {
        console.log(`adding js ready: ${js.substr(0, 30)}...`);
        this._scriptsOnReady.push(js);
        this._executeReadyScripts();
    }

    _scriptCompletedLoading() {
        this._scriptsLoading -= 1;
        this._executeReadyScripts();
    }

    _executeReadyScripts() {
        if (this._scriptsLoading <= 0) {
            const tags = this._scriptsTags;
            this._scriptsTags = [];
            tags.forEach((script) => {
                console.log(`writing script: ${script.substr(0, 60)}...`);
                // $("<script>alert('adding script');</script>").appendTo(document.body);
                $(script).appendTo(document.body);
            });
            const scripts = this._scriptsOnReady;
            this._scriptsOnReady = [];
            scripts.forEach((js) => {
                console.log(`running script: ${js.substr(0, 60)}...`);
                try {
                    // http://perfectionkills.com/global-eval-what-are-the-options/#evaling_in_global_scope
          (1, eval)(js); // eslint-disable-line
                } catch (err) {
                    console.error(`Error executing js: ${err}`);
                }
            });
        }
    }

    _hashText(text) {
        let hash = 0;
        if (text.length === 0) return hash;
        for (let i = 0; i < text.length; i += 1) {
            const chr = text.charCodeAt(i);
      hash = ((hash << 5) - hash) + chr; // eslint-disable-line
            // eslint-disable-next-line
      hash |= 0; // Convert to 32bit integer
        }
        return hash;
    }
}


/**
 * The App class acts as a central controller for the whole application.
 * It contains members for accessing the api (server api) and current state of the application.
 */
class App {
  constructor() {
    console.log('Creating App()');
    this.document = new AppDocument();
    this.document.preloaded = config.preloaded; // Object.assign({}, window.appState.preloaded);
    this._api = new Api(config.apiLocation);
    this._ui = new UIHandler(null);
    this.pageSwitchesCounter = 0;
    this._reduxStore = null;
    this._routerHistory = null;
    this.accessToken = null;
    this._logger = null;
    this._refreshTimeoutId = null;

    window.app = this; // Set the app as a global javascript variable
    window.getDeviceType = () => {
      const element = document.getElementById('deviceTest');
      return window.getComputedStyle(element).getPropertyValue('z-index');
    };

    // Start the refresh stuff
    this.startAutomaticRefresh();
  }

  /*
     * Getter method for retrieving an instance of the api.
     */
    get api() { return this._api; }
    /*
     * Getter method for retrieving an instance of the ui handler.
     */
    get ui() { return this._ui; }
    /*
    * Getter method for retrieving an instance of the logger.
    */
    get logger() { return this._logger; }

    set logger(logger) {
        this._logger = logger;
    }

    /**
     * Get the currently loaded version of the client
     */
    get clientVersion() { return config.clientVersion; }

    /**
     * Start (and stop) automatic server communication ("refresh")
     * 
     * 
     */
    startAutomaticRefresh() {
        this._refreshTimeoutId = window.setTimeout(() => { this._performRefresh(20000); }, 3000);
    }
    stopAutomaticRefresh() {
        // TODO: doesn't do anything
    }


  /**
   * Get the prefix to prepend to all application links
   */
    get linkPrefix() {
        const state = this._reduxStore.getState();
        const companyId2 = state.company ? `${state.company.id2}/` : '';
        return `${state.urlPrefix + companyId2}`;
    }

    /**
   * Cleverly open (or goto) a link. Always use this instead of directly using history.push or
   * window.location, when the link may be one that should be dynamically loaded
   */
    gotoLink(url) {
        // This piece of shit cannot handle the protocol and host part of the url
        // https://github.com/ReactTraining/react-router/issues/1147
        let fixedUrl = url.replace(/https?:/, '');
        fixedUrl = fixedUrl.replace(/\/\/(www|dev).chronox.se/, '');
        let escapedLinkPrefix = app.linkPrefix.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        fixedUrl = fixedUrl.replace(new RegExp(escapedLinkPrefix,""), '/');
        const location = createLocation(fixedUrl, null, null, app._routerHistory.location);
        console.log('App::gotoLink() ', fixedUrl, location);

        // Is it a download?
        if (fixedUrl.match(/download\/files/) || fixedUrl.match(/resource=/) || fixedUrl.match(/_temp\/formfiles/)) {
            console.log('App::gotoLink() initiating download', fixedUrl, location);
            window.location = fixedUrl;
            return;
        }

        if (fixedUrl.match(/rapport\/\?login=/)) {
            console.log('App:gotoLink() found reporter login link');
            this.ui.featureNotAvailable();
            return;
        }

        this.incrementPageSwitches();
        app._routerHistory.push(location);
    }

    getCurrentPath() {
        return app._routerHistory.location.pathname;
    }

    get store() { return this._reduxStore; }
    set store(value) {
        this._reduxStore = value;

        value.subscribe(() => {
            const rp = value.getState().preloaded; // Redux preloaded
            const cp = this.document.preloaded; // The current preloaded
            const compareRp = JSON.stringify(rp);
            const compareCp = JSON.stringify(cp);

            if (compareRp !== compareCp) {
                this.document.preloaded = value.getState().preloaded;
                console.log('updated preloaded');
            }

            if (value.getState().auth.isSignedIn) {
                this._logger.setUser(value.getState().user);
            }
        });
    }

    get chronoxLogoUrl() {
        return `https://www.chronox.se${this._reduxStore.getState().urlPrefix}_gfx/chronox-logo-final-small.png`;
    }

    get pageSwitchesCount() {
        return this.pageSwitchesCounter;
    }

    incrementPageSwitches() {
        this.pageSwitchesCounter += 1;
    }

    /**
   * Execute and handle the periodic refresh
   */
    _performRefresh(nextRefreshTimeoutIn = 0) {
        let nextRefreshTimeout = nextRefreshTimeoutIn;
        this._refreshTimeoutId = null;
        this.api.refresh().then((response) => {
            // Check if a new client version is available
            if (
                response.appState.clientVersion &&
        config.clientVersion &&
        (response.appState.clientVersion !== config.clientVersion)
            ) {
                this.ui.newClientVersionAvailable(true);
                nextRefreshTimeout = 0;
            }

            // Check if the user is signed out
            if (!response.appState.auth.isSignedIn || !response.appState.user) {
                this.ui.hasBeenSignedOut();
                nextRefreshTimeout = 0;
            }

            if (nextRefreshTimeout) {
                this._refreshTimeoutId = window.setTimeout(() =>
                    this._performRefresh(nextRefreshTimeout), nextRefreshTimeout);
            }
        }).catch(() => {
            if (nextRefreshTimeout) {
                this._refreshTimeoutId = window.setTimeout(() =>
                    this._performRefresh(nextRefreshTimeout), nextRefreshTimeout);
            }
        });
    }
}

/**
 * In case of complete react rendering, use this class.
 * It renders the whole application.
 * Simply call, first: var reactApp = React.createElement(ReactApp, {}, null);
 * then call: ReactDOM.render(reactApp, document.getElementById('app'));
 *
 * note: the document.getElementById call is only an example. This should be the
 */
class ReactApp extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            sidebarVisible: false,
            isMobile: window.innerWidth < 825,
            // centralDialogOpen: false, // XXX: Or should we read redux state?
        };

        this.handleSidebarClick = this.handleSidebarClick.bind(this);
        this.handleMobile = this.handleMobile.bind(this);
        this.handleReEntry = this.handleReEntry.bind(this);

        window.addEventListener('resize', this.handleMobile);

        app._routerHistory = this.props.history;
    }

    componentDidMount() {
    // Fires the jquery ready wrappers
        window.jQuery.holdReady(false);
        this.handleMobile();
        window.addEventListener('scroll', () => sessionStorage.setItem('latestScrollTop', document.body.scrollTop));
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.props.onPageChange();
        }
    }

    // FIXME: the isMobile state should be put in redux
    handleMobile() {
        if (window.getDeviceType() >= Number('3')) {
            if (!this.state.isMobile) {
                this.setState({ isMobile: true });
            }
        } else if (this.state.isMobile) {
            this.setState({ isMobile: false });
        }
    }

    handleSidebarClick() {
        const nextVisible = !this.state.sidebarVisible;

        this.setState({
            sidebarVisible: nextVisible,
        });
    }

    /* FIXME: ugly code, this should be refactored ASAP! */
    handleReEntry() {
    // If the user clicks the backbutton
        window.onpopstate = function onpopstate() {
            sessionStorage.setItem('wasPop', 'yes');
        };

        // True if the back button was clicked before arriving to the current page, false if not
        const wasPop = sessionStorage.getItem('wasPop');
        const wasBack = wasPop ? wasPop.includes('yes') : false;

        // If a different page was opened
        if (sessionStorage.getItem('lastPage') !== this.props.history.location.pathname) {
            console.log('Last page was not the same');
            if (!wasBack) {
                // Reset the re entry input, which will tell the state manager to reset the state
                document.getElementById('re-entry').value = '';
                // Set the current page to lastPage, in order for the next check to work
                sessionStorage.setItem('lastPage', this.props.history.location.pathname);
                // Set the localstorage item as well (since the window does not always refresh)
                localStorage.setItem('re-entry', 'yes');
            } else {
                document.getElementById('re-entry').value = 'refresh';
                localStorage.setItem('re-entry', 'no');

                document.body.scrollTop = sessionStorage.getItem('latestScrollTop') || 0;

                if (sessionStorage.getItem('wasPop') === 'yess') {
                    sessionStorage.setItem('wasPop', 'no');
                } else {
                    sessionStorage.setItem('wasPop', 'yess');
                }
            }
        }
    }

    render() {
        sessionStorage.removeItem('goToAfterPost');
        const path = window.location.search.substring(1);

        const postContentRender = app.postContent || $('<span>Fel vid inläsning</span>');

        const {
            onContentClick,
            centralDialog,
        } = this.props;
        const { isMobile } = this.state;
        const divPageStyle = isMobile ? {} : { width: 'calc(100% - 18em)', float: 'right'/* , paddingBottom: '500px' */ };
        divPageStyle.paddingTop = '50px';

        this.handleReEntry();

        // NOTE: Should "url/path" be a prop of App, which is passed down to Router?

        let modalClasses = '';
        let modalChildrenClasses = '';
        switch (centralDialog.type) {
            case centralDialogTypes.info:
                modalClasses = `${styles.infoDialogContainer} ${styles.infoDialogColor}`;
                modalChildrenClasses = styles.infoDialogColor;
                break;
            case centralDialogTypes.warn:
                modalClasses = `${styles.warnDialogContainer} ${styles.warnDialogColor}`;
                modalChildrenClasses = styles.warnDialogColor;
                break;
            case centralDialogTypes.error:
                modalClasses = `${styles.errorDialogContainer} ${styles.errorDialogColor}`;
                modalChildrenClasses = styles.errorDialogColor;
                break;
            default:
                break;
        }


        function handleDialogClose() {
            const { callback } = centralDialog;
            app.ui.centralDialogClose();
            if (callback) {
                callback();
            }
        }

        const bigDialog = (
            <Modal
                open={centralDialog.open}
                dimmer="blurring"
                closeOnEscape={centralDialog.locked}
                closeOnRootNodeClick={centralDialog.locked}
                className={modalClasses}
            >
                <Modal.Header className={modalChildrenClasses}>
                    {centralDialog.header}
                </Modal.Header>
                <Modal.Content className={modalChildrenClasses}>
                    <p>{centralDialog.message}</p>
                </Modal.Content>
                <Modal.Actions className={modalChildrenClasses}>
                    <Button positive onClick={handleDialogClose}>Ok</Button>
                </Modal.Actions>
            </Modal>
        );

        const containerClasses = isMobile ? 'conditional-maximizeWidth' : 'conditional-maximizeWidth';

        return (
            <div
                id="container"
                className={containerClasses}
            >
                <ContentMenu
                    mobile={isMobile}
                    style={{
                        position: 'fixed',
                        fontSize: '1rem',
                        height: 'calc(100% - 70px)',
                        top: '70px',
                    }}
                />
                <header>
                    <ContentHeader mobile={isMobile} />
                    <ContentSubMenu />
                </header>
                <Sidebar.Pushable>
                    <Sidebar.Pusher>
                        <div id="content" role="none" onClick={onContentClick}>
                            <div id="page" style={divPageStyle}>
                                <div id="userArea">
                                    <div id="deviceTest" className={deviceTestCss.deviceTest} />
                                    {/* Parse possible old urls (i.e. ?mode=...) */}
                                    {/* to new ones that router can handle */}
                                    <QueryParser />

                                    {/* If a post request was received */}
                                    {/* (see main.jsx for the setting of
                                        app.postContent and /p url)
                                    */}
                                    <Route
                                        exact
                                        path="/p"
                                        render={() => (
                                            <OldLinksWrapper>
                                                {/* eslint-disable-next-line react/no-danger */}
                                                <div id="postContent" dangerouslySetInnerHTML={{ __html: postContentRender.html() }} />
                                            </OldLinksWrapper>
                                        )}
                                    />

                                    <Route
                                        path="/"
                                        render={() => (
                                            <div>
                                                <Route exact path="/" component={PageOverview} />
                                                <Route exact path="/ledgers" component={PageLedgers} />
                                                <QueryRoute path="/ledgers/:ledger" component={Page.Ledger.LedgerPageFactory} />
                                                <Route exact path="/report" component={PageReportOverview} />
                                                <Route path="/report/timesheets/:period" component={PageReport} />
                                                <Route exact path="/report/workreports/order/:order" component={PageReportWorkReport} />
                                                <Route exact path="/report/workreports/order/:order/day/:day" component={PageReportWorkReport} />
                                                <Route exact path="/reports" component={PageReports} />
                                                <Route exact path="/reports/:period" component={PageReports} />
                                                <Route exact path="/reports/timesheets/:period" component={PageTimereport} />
                                                <Route path="/reports/timesheets/:period/:employee" component={PageTimereport} />
                                                <Route path="/reports/workreports/:order" component={PageWorkReport} />
                                                <Route exact path="/employees" component={PageEmployees} />
                                                <Route exact path="/employees/:employee" component={PageEmployee} />
                                                <Route exact path="/employees/:employee/page" component={PageEmployeePage} />
                                                <Route exact path="/projects" component={PageProjects} />
                                                <Route path="/projects/:project" component={PageProject} />
                                                <Route exact path="/users" component={PageUsers} />
                                                <Route path="/users/:user" component={PageUser} />
                                                <Route exact path="/settings" component={PageSettings} />
                                                <Route path="/settings/workorders/:configId" component={PageWorkOrderConfig} />
                                                <Route path="/settings/workreports/:configId" component={PageWorkReportConfig} />
                                                <Route exact path="/tools" component={PageTools} />
                                                <Route path="/tools/exports/:target/:job" component={PageExport} />
                                                <Route path="/apitest" component={PageApiTest} />
                                                <Route path="/componentstest" component={TestPage} />
                                                {/* Unknown mode */}
                                                <Route
                                                    path="/m/:mode"
                                                    render={(props) => {
                                                        const queryVariables = {};
                                                        path.split('&').forEach((element) => {
                                                            const pair = element.split('=');
                                                            const key = pair[0];
                                                            const value = pair[1];
                                                            queryVariables[key] = value;
                                                        });

                                                        return (
                                                            <OldView
                                                                {...queryVariables}
                                                                mode={props.match.params.mode}
                                                            />
                                                        );
                                                    }}
                                                />
                                            </div>
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </Sidebar.Pusher>
                </Sidebar.Pushable>
                {bigDialog}
            </div>
        );
    }
}

ReactApp.propTypes = {
    history: PropTypes.shape({
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({}),
    }).isRequired,
    location: PropTypes.shape({}).isRequired,
    onPageChange: PropTypes.func.isRequired,
    onContentClick: PropTypes.func.isRequired,
    centralDialog: PropTypes.shape({}).isRequired,
};

const mapDispatchToProps = dispatch => Object.assign({}, {
    onPageChange: () => dispatch(pageChange()),
    onContentClick: () => dispatch(clickOnContent()),
});

const mapStateToProps = state => Object.assign({}, {
    centralDialog: state.centralDialog,
});


const ReactAppReduxContainer = connect(mapStateToProps, mapDispatchToProps)(ReactApp);
const ReactAppWithRouter = withRouter(ReactAppReduxContainer);


export { App, ReactAppWithRouter as ReactApp };
