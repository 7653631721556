import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Button, Icon } from 'semantic-ui-react';


const HOUR_PART = 'hour';
const MINUTE_PART = 'minute';
const AM_PM_PART = 'ampm';

/**
 * This function transforms a hour of the day to
 * a am/pm representation. In other words
 * the function makes sure that the hour
 * stays between 01 and 12.
 * Its purpose is to convert 24 hour
 * clock values to 12 hour clock values.
 *
 * @param {Number} hour the hour to get a representation for. Needs to be a number.
 */
function getAMPMRepresentation(hour) {
    // If the hour is not a number
    // simply return it.
    if (Number.isNaN(Number(hour))) {
        return hour;
    }

    // Anything outside of the allowed span
    // and 0 gets represented as 12.
    if (hour < 0 || hour > 24 || hour === 0) {
        return '12';
    }

    // Convert the hour to a number.
    let result = Number(hour);

    // If the hour is above 12.
    // remove 12 from it.
    if (result > 12) {
        result -= 12;
    }

    // If the hour is below 10
    // add a 0 before it
    // so that we can represent it as
    // 01, 02, 03 etc.
    if (result < 10) {
        result = `0${result}`;
    }

    return `${result}`;
}

/**
 * Helper method used for updating the time part of the state
 * of the TimeField component.
 * The time part has the signature, valueParts: { hour, minute, ampm }.
 * @param {React.Component.state} state the state.
 * @param {Number} value the value to update with.
 * @param {String} nextPart the part that should next be active.
 *   Should be HOUR_PART, MINUTE_PART or AM_PM_PART.
 * @param {String} writeToKey the key of the part to write to.
 *   Should be 'hour' or 'minute'.
 */
function getUpdatedTimePartState(state, value, nextPart, writeToKey) {
    // Make a safe copy of the existing state and use that as
    // the default return of the function.
    let resultingState = Object.assign({}, state);
    // Retrieve the valueParts and firstKeyWritten state variables.
    const { valueParts, firstKeyWritten } = resultingState;

    // If the value is not a number, it is most likely the
    // am/pm part, which cannot be handle by this method.
    // In that case simply return the original state.
    if (Number.isNaN(value)) {
        return resultingState;
    }

    // Stores the updated parts (valueParts.minute or valueParts.hour).
    const newParts = {};

    // If the first digit of either the hour or the minute part has already
    // been written (e.g. The 1 of 12 or the 0 of 04).
    if (firstKeyWritten) {
        // In this case the value has to be concatenated as a string
        // and then converted into a number. e.g. if the first key was
        // 1 and the second 2, it should result in 12 not 3.
        newParts[writeToKey] = Number(`${valueParts[writeToKey]}${value}`);
        resultingState = {
            valueParts: Object.assign({}, valueParts, newParts),
            // Jump back to the first digit.
            firstKeyWritten: false,
            // Go to the next part (hour or ampm).
            onPart: nextPart,
        };
        console.log(resultingState);
    } else {
        newParts[writeToKey] = value;
        resultingState = {
            valueParts: Object.assign({}, valueParts, newParts),
            // Jump to the next part.
            firstKeyWritten: true,
        };
    }

    return resultingState;
}

/**
 * Returns the part that was clicked (minute, hour or ampm),
 * using the x position of the click event.
 *
 * @param {Number} x x position of the click event.
 */
function getPartFromClickXPosition(x) {
    if (x > 30 && x < 50) {
        return MINUTE_PART;
    } else if (x >= 50 && x <= 80) {
        return AM_PM_PART;
    }

    return HOUR_PART;
}

class TimeField extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            valueParts: {
                hour: props.defaultValue.substring(0, 2),
                minute: props.defaultValue.substring(3, 5),
                ampm: props.defaultValue.substring(6, 8),
            },
            onPart: HOUR_PART,
            // Used for the hour and minute parts.
            // Both should have a number with two digits
            // and we need to keep track of which
            // digit has been written for each.
            firstKeyWritten: false,
            inputDone: false,
            inputFocus: false,
            clickXPosition: -1,
        };

        this.inputRef = null;
        this.handleInputRef = this.handleInputRef.bind(this);
        this.handleInputFocus = this.handleInputFocus.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleClearInput = this.handleClearInput.bind(this);
        this.handleInputBlur = this.handleInputBlur.bind(this);
    }

    componentDidUpdate() {
        // If the component is somehow updated
        // the input field loses focus.
        // Therefore, we save it in the state of the component
        // in order to refocus the input.
        if (this.state.inputFocus) {
            this.handleInputFocus();
        }
    }

    handleInputRef(ref) {
        // Get the ref from semantic's wrapper.
        this.inputRef = ref.inputRef;
        // Add a focus listener.
        this.inputRef.onclick = event => this.setState({
            inputFocus: true,
            clickXPosition: event.layerX,
        });
    }

    // This method gets called whenever the input gains focus.
    handleInputFocus() {
        const { onPart, inputDone, clickXPosition } = this.state;
        // If the input was not clicked, the stored part is chosen
        let currentPart = onPart;

        // When the input gets focused, if the input is completed and a
        // click position is set, it means that it was clicked and not
        // focused programmatically.
        if (inputDone && clickXPosition >= 0) {
            currentPart = getPartFromClickXPosition(clickXPosition);
            // Set clickXPosition to -1 in order to prevent an infinite loop.
            this.setState({ onPart: currentPart, clickXPosition: -1 });
        }

        // Select the part that was clicked or stored.
        if (currentPart === HOUR_PART) {
            this.inputRef.setSelectionRange(0, 2);
        } else if (currentPart === MINUTE_PART) {
            this.inputRef.setSelectionRange(3, 6);
        } else if (currentPart === AM_PM_PART) {
            this.inputRef.setSelectionRange(6, 7);
        }

        this.inputRef.focus();
    }

    // This function makes sure that the input does not continue
    // to focus after the user has left the field.
    // Without this function, the input would be focused on
    // every call to componentDidUpdate.
    handleInputBlur() {
        this.setState({ inputFocus: false });
    }

    // This method gets called on any input change, i.e. when the user types.
    handleInputChange(event, object) {
        const { valueParts, onPart } = this.state;
        let resultingState = this.state;

        // Switch between the parts, and set the state accordingly.
        if (onPart === HOUR_PART) {
            // Retrieve the relevant part (hour part) of the store value.
            const receivedValue = object.value.substring(0, 1);
            // Get the updated state.
            resultingState = getUpdatedTimePartState(this.state, receivedValue, MINUTE_PART, HOUR_PART); // eslint-disable-line max-len
        } else if (onPart === MINUTE_PART) {
            // Retrieve the relevant part (minute part) of the stored value.
            const receivedValue = object.value.substring(3, 4);
            // Get the updated state.
            resultingState = getUpdatedTimePartState(this.state, receivedValue, AM_PM_PART, MINUTE_PART); // eslint-disable-line max-len
        } else if (onPart === AM_PM_PART) {
            // Get the typed in key. Should be 'p' or 'a'.
            // If anything else gets entered, it gets ignored.
            const key = object.value.substring(6, 7).toLowerCase();
            // As soon nas a 'p' or 'P' gets entered
            // set the ampm part to 'PM' and
            // complete the input.
            if (key === 'p') {
                resultingState = Object.assign({}, this.state, {
                    valueParts: Object.assign({}, valueParts, { ampm: 'PM' }),
                    inputDone: true,
                });
            // Likewise, if an 'a' or 'A' gets entered
            // set the ampm part to 'AM'.
            } else if (key === 'a') {
                resultingState = Object.assign({}, this.state, {
                    valueParts: Object.assign({}, valueParts, { ampm: 'AM' }),
                    inputDone: true,
                });
            }
        }

        // Update the local state and
        // ItemEditForm with the updated value
        // of the field.
        this.props.onChange(this.props.id, valueParts);
        this.setState(resultingState);
    }

    handleClearInput(event) {
        event.preventDefault();

        // Reset the state to its initial values.
        this.setState({
            valueParts: {
                hour: '--',
                minute: '--',
                ampm: '--',
            },
            onPart: HOUR_PART,
            firstKeyWritten: false,
            inputDone: false,
            inputFocus: false,
            clickXPosition: -1,
        });
    }

    render() {
        const {
            id,
            label,
        } = this.props;

        const {
            valueParts,
        } = this.state;

        // Get the value to display, which is a bit different
        // from the value that is stored.
        const hour = getAMPMRepresentation(valueParts.hour);
        const value = `${hour}:${valueParts.minute} ${valueParts.ampm}`;

        const element = (
            <Form.Field>
                <label htmlFor={id}>{label}</label>
                <Input
                    action={(
                        <Button color="red" onClick={this.handleClearInput} icon>
                            <Icon name="delete" />
                        </Button>
                    )}
                    id={id}
                    placeholder="00:00"
                    value={value}
                    onChange={this.handleInputChange}
                    ref={this.handleInputRef}
                    onBlur={this.handleInputBlur}
                />
            </Form.Field>
        );
        return element;
    }
}

TimeField.defaultProps = {
    onChange: () => console.warn('No onChange function passed down to TimeField'),
};

TimeField.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    defaultValue: PropTypes.string.isRequired,
    onChange: PropTypes.func,
};

export default TimeField;
