import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';

import { ModeSwitcher } from 'Toolkit';
import OldComponent from 'Toolkit/OldComponent';

function HeaderBlock(props) {
    const { ledgerId, isGrouped, t } = props;
    const options = [
        {
            title: t('company'),
            onClick: () => console.log('Clicked företagets.'),
        },
        {
            title: t('collected'),
            onClick: () => console.log('Clicked samlad.'),
        },
    ];
    const activeIndex = isGrouped ? 1 : 0;

    const element = (
        <div>
            <ModeSwitcher options={options} active={activeIndex} />
        </div>
    );
    return <OldComponent namespace="Client.AdminWebV2.Components" name="App.Ledger.HeaderBlock" ledger={ledgerId} grouped={isGrouped ? 1 : 0} />;//element;
}

HeaderBlock.propTypes = {
    ledgerId: PropTypes.string.isRequired,
    isGrouped: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
};

const finalComponent = translate('Ledger')(HeaderBlock);

export default finalComponent;
