exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@charset \"UTF-8\";\n\n/**\n * This css document contains the necessary style for the component Toolkit/ChronoxBox of the Chronox react ui toolkit v1.\n */\n \n .crVamE18QtxKSr8TdcZAf {\n \theight: 100%;\n \twidth: 100%;\n }\n \n .wA3gWqhmYU6DLBQm8jm_H {\n \tpadding: 0 !important;\n \tmargin: 0 !important;\n }\n \n ._28Un-GH9U3iypeVnKPTG2S {\n \tpadding: 0 !important;\n }\n ", ""]);

// exports
exports.locals = {
	"full": "crVamE18QtxKSr8TdcZAf",
	"grid": "wA3gWqhmYU6DLBQm8jm_H",
	"column": "_28Un-GH9U3iypeVnKPTG2S"
};