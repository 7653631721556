import React from 'react';
import { Grid } from 'semantic-ui-react';

import { View } from 'Toolkit';
import CurrentProjectsSection from 'Container/CurrentProjectsSection';
import OldProjectsSection from 'Container/OldProjectsSection';
import OldView from 'Toolkit/OldView.jsx';

/** A page in the application that shows an overview of the projects created by the company. */
class PageProjects extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      stack: window.innerWidth < 1920,
    };

    this.handleStack = this.handleStack.bind(this);
    window.addEventListener('resize', this.handleStack);
  }

  handleStack() {
    if (this.state.stack && window.innerWidth > 1920) {
      this.setState({ stack: false });
    }

    if (!this.state.stack && window.innerWidth < 1920) {
      this.setState({ stack: true });
    }
  }

  render() {
    // const { stack } = this.state;

    /* if (sessionStorage.lastPage !== 'project') {
      console.log('Not refresh');
      sessionStorage.lastPage = 'project';
    } else {
      console.log('REFRESH');
    } */

    /* const unstackedElement = (
      <View>
        <Grid stackable columns={2}>
          <Grid.Column width={8}>
            <CurrentProjectsSection />
          </Grid.Column>
          <Grid.Column width={8}>
            <OldProjectsSection />
          </Grid.Column>
        </Grid>
      </View>
    ); */

    const stackedElement = (
      <View>
        <CurrentProjectsSection />
        <br />
        <br />

      </View>
    );

    const oldView = <OldView refresh={false} mode="projects" />;

    return oldView; // stack ? stackedElement : unstackedElement;
  }
}

export default PageProjects;
